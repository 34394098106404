import Ajax from "../helpers/ajax";

const init = { byFormId: {} };

export function simpleApi(route, body) {
  return function (dispatch, getState) {
    var state = getState();

    return Ajax.post(dispatch, `crm/${route}`, body)

  }
}


export default (state = init, action) => {

  //switch (action.type) {
  //}
  return state;
}
