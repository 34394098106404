import { alert } from "redux/reducers/header";
import { onFieldChange, onFieldsChange } from "redux/reducers/form";


const onPagoDocumentoFieldChange = (_this, formId, name, value, extra) => {

  var form = _this.props.form.item;

  if (["lineaPagoDocumento"].indexOf(name) > -1) {
    var total = value.reduce((acumulator, lineaPago) => { return acumulator + lineaPago.monto; }, 0);
    return _this.props.dispatch(onFieldsChange(formId, { monto: total, lineaPagoDocumento: value }));
  }

  return _this.props.dispatch(onFieldChange(formId, name, value));
}

export default onPagoDocumentoFieldChange;