import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListSubheader from '@material-ui/core/ListSubheader';



import { Link } from "react-router-dom";

import TabletMac from '@material-ui/icons/TabletMac';
import LocationCity from '@material-ui/icons/LocationCity';
import AccountBalance from '@material-ui/icons/AccountBalance';
import MoveToInbox from '@material-ui/icons/MoveToInbox';
import Payment from '@material-ui/icons/Payment';
import Exit from '@material-ui/icons/ExitToApp';
import Visibility from '@material-ui/icons/Visibility';



import AttachMoney from '@material-ui/icons/AttachMoney';
import Settings from '@material-ui/icons/Settings';
import CardHeader from "components/Card/CardHeader.jsx";
import Colibri from "assets/img/colibri.svg";



import { connect } from "react-redux";
import { onLogout } from "redux/reducers/user";


import Collapse from '@material-ui/core/Collapse';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';

import Menu from "components/Sidebar/menu";

import image from "assets/img/bg-6.jpg";

const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

const iconMap = {
  TabletMac: TabletMac,
  LocationCity: LocationCity,
  AccountBalance: AccountBalance,
  MoveToInbox: MoveToInbox,
  Payment: Payment,
  AttachMoney: AttachMoney,
  Settings: Settings,
  Visibility: Visibility
}


const styles = theme => ({

  list: {
    width: 250,
    opacity: ".53",
    color: "#fff",
    height: "100%",
    opacity: ".93",
    zIndex: "1",
    height: "100%",
    width: "100%",
    display: "block",

    backgroundSize: "cover",
    backgroundPosition: "center center",
    transition: "all 300ms linear",
  },
  background: {

  },

  logo: {
    width: "65%",
    padding: "15% 5%",
    paddingRight: 0
  },


  bgImage: {
    backgroundSize: "100%",
    boxShadow: "0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22)"
  },
  root: {
    backgroundImage: "url(" + image + ")",
    backgroundColor: theme.palette.primary[800],
    borderRight: "3px solid #fff",
    backgroundPosition: "-169px -216px"
  },
  sidebarRoot: {

  },
  subheader: {
    background: "rgba(255,255,255,0.1)",
    color: "#fff",
    position: "relative",
    textDecoration: "none",
    fontSize: 18,
    textShadow: "1px 4px 4px #333"

  },
  item: {
    background: "rgba(255,255,255,0.2)",

    color: "#fff",
    position: "relative",
    textDecoration: "none",
    // textShadow: "1px 1px 1px #333"
  },
  text: {
    color: "#fff",
    fontWeight: 400,
    textShadow: "0.8px 4px 4px #333",
    fontSize: 17,
  },
  icon: {
    boxShadow: "0.5px 4px 4px #777",

    fill: theme.palette.secondary[50],


  }
});

const mapStateToProps = (store, props) => {
  return {
    user: store.user.user
  }
}

class TemporaryDrawer extends React.Component {

  menuList() {
    return [
    ]
  }

  onLogout = () => {
    this.dispatch(onLogout());
  }


  render() {
    const { classes } = this.props;

    return (
      <div style={{ zIndex: 0 }}>
        <Drawer classes={{ paper: classes.root }}
          open={this.props.open}
          onClose={this.props.onClose}>

          <div className={classes.sidebarRoot}>
            <div className={classes.background}>
              <div
                className={`${classes.bgImage} ${classes.textCenter}`}

              >
                <img className={classes.logo} src={Colibri} />
              </div>

              <Menu  onClose={this.props.onClose} items={this.props.items} />

            </div>
          </div>
        </Drawer>



      </div>
    );
  }
}

TemporaryDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps)(TemporaryDrawer));


