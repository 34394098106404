import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import red from '@material-ui/core/colors/red';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import Button from '@material-ui/core/Button';
import blue from '@material-ui/core/colors/blue';
import { onLoadMetaData, selectMetadataByType } from "redux/reducers/metadata";
import { selectDataByType, onLoadData, onSave, onAction } from "redux/reducers/data";
import { connect } from "react-redux";
import SmartForm from "components/SmartForm/Form";

import Checkbox from "@material-ui/core/Checkbox";

import moment from "moment";

// material-ui icons
import Assignment from "@material-ui/icons/Assignment";
import PanTool from "@material-ui/icons/PanTool";
import Edit from "@material-ui/icons/Edit";
import Check from "@material-ui/icons/Save";

import ArrowBack from "@material-ui/icons/ArrowBack";
import Add from "@material-ui/icons/Add";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import AppBar from '@material-ui/core/AppBar';

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Hidden from "@material-ui/core/Hidden";
import { throttle, debounce } from "throttle-debounce";
import { Switch, Route, Redirect } from "react-router-dom";

// material-ui components
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";


import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";

import { onCreateForm, onFieldChange, onUpdateField, onUpdateItem, selectFormById } from "redux/reducers/form";


import { onLoadDataById, selectDataByTypeAndId } from "redux/reducers/data";


import { simpleApi, selectApiResponseByFormId } from "redux/reducers/api";

import CircularProgress from '@material-ui/core/CircularProgress';
import CustomInput from "components/CustomInput/CustomInput";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import CardText from "components/Card/CardText.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';

import Tooltip from '@material-ui/core/Tooltip';

import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';

export const styles = theme => ({
  root: {
    marginTop: "-8px",
  },
  main: {
    margin: theme.spacing.unit * 12,
    marginTop: 0
  },
  toolbar: {
    margin: theme.spacing.unit * 4,
    marginTop: "10px",
    marginBottom: 0
  },
  button: {
    margin: theme.spacing.unit * 1
  },
  dialog: { margin: 10 }

});

class Form extends React.Component {

  constructor() {
    super();
    this.state = {
      dialogOpen: false,
      loadingMetadata: false
    }
  }

  _componentWillMount() { }

  componentWillMount() {
    return this._componentWillMount()
  }

  _componentWillReceiveProps(nextProps) { }

  componentWillReceiveProps(nextProps) {
    return this._componentWillReceiveProps(nextProps);
  }

  _onFieldChange = (formId, name, value, extra) => {
    this.props.dispatch(onFieldChange(formId, name, value, extra));
  }

  onFieldChange = (formId, name, value, extra) => {
    return this._onFieldChange(formId, name, value, extra);
  }

  onBack = () => {
    this.props.onClose ? this.props.onClose() : this.props.history.goBack();
  }

  onShowModal = (name, params) => {
    this.setState({ dialogOpen: true, dialogName: name, dialogParams: params })
  }

  onCloseModal = () => {
    this.setState({ dialogOpen: false, dialogName: null })
  }

  getTypeMap() {
    return {}
  }

  onFastCreate = (namespace, metadataType, operation, formId, assignToType, closeOnSuccess) => {
    //Doing this so state is serializable, we'll see how it turns out
    this.FastCreateComponent = this.props.getComponentFromPath(metadataType, operation);
    this.setState({
      fastCreate: {
        metadataType: metadataType,
        operation: operation,
        namespace: namespace,
        formId: formId || Math.random(),
        assignToType: assignToType,
        closeOnSuccess: closeOnSuccess
      }
    })
  }

  handleFastCreateClose = (createdResult) => {
    if (createdResult) {
      this.onFieldChange(this.props.formId, this.state.fastCreate.assignToType, createdResult.id, createdResult)
      this.props.dispatch(onFieldChange(this.props.formId, "__" + this.state.fastCreate.assignToType, createdResult.name))

    }
    this.setState({ fastCreate: null })
    this.FastCreateComponent = () => null;
  }

  renderFastCreateModal() {
    const { classes } = this.props;
    if (!this.state.fastCreate) return null;

    return <Dialog
      classes={{
        root: classes.center,
        paper: classes.modal
      }}
      maxWidth="lg"
      open={this.state.fastCreate != null}
      onClose={this.handleFastCreateClose}

      aria-labelledby="form-dialog-title"
    >

      <DialogContent classes={{ root: classes.dialog }}>

        <this.FastCreateComponent
          formId={this.state.fastCreate.formId}
          metadataType={this.state.fastCreate.metadataType}
          onClose={this.handleFastCreateClose}
        />

      </DialogContent>
    </Dialog>
  }

  renderModal() {
    const { classes } = this.props;
    if (!this.state.dialogOpen) return null;
    var modalContent = this[`render${this.state.dialogName}Modal`](this.onCloseModal);
    if (!modalContent) return null;

    return <Dialog
      classes={{
        root: classes.center,
        paper: classes.modal
      }}
      maxWidth="md"
      open={this.state.dialogOpen}
      onClose={this.onCloseModal}
      aria-labelledby="form-dialog-title"
    >

      <DialogContent>
        <div>
          {modalContent}
        </div>
      </DialogContent>
    </Dialog>

  }

  renderForm() {
    if (!this.props.form || !this.props.metadata) return null;

    return <SmartForm
      typeMap={this.getTypeMap()}
      onFieldChange={this.onFieldChange}
      form={this.props.form || {}}
      metadata={this.props.metadata || {}}
      dataItem={this.props.dataItem}
      formId={this.props.formId}
      metadataType={this.props.metadataType}
      id={this.props.id}
      showCreateModal={this.showCreateModal}
      onFastCreate={this.onFastCreate}
      onShowModal={this.onShowModal}
      onGoBack={this.props.onGoBack}
    />
  }

  getOtherActions() {
    return [];
  }

  render() {
    const { classes } = this.props;
    if (!this.props.metadata) return null;

    return <div className={classes.root}>

      <div className={classes.toolbar}>

        <CardHeader

          action={[
            <Button onClick={this.onBack} variant="fab" mini color="default" aria-label="Add" className={classes.button}>
              <ArrowBack />
            </Button>,
            <Button onClick={this.onSave} variant="fab" color="primary" aria-label="Add" className={classes.button}>
              <Check />
            </Button>].concat(this.getOtherActions())

          }
          title={<Typography variant="title">{this.props.metadata.title}</Typography>}

          subheader={this.props.subTitle}
        />
      </div>

      <div className={classes.main}>
        {this.renderForm()}
        {this.renderFastCreateModal()}
        {this.renderModal()}
      </div>

    </div>

  }
}

export default Form;
