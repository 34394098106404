const Dinero = require('dinero.js')
Dinero.globalLocale = 'es-CR';
Dinero.defaultPrecision = 5;

var Math = {};

Math.ordenTotal = function (lineas) {


  let subTotal = Dinero({ amount: 0 })
  let subTotalConDescuento = Dinero({ amount: 0 });
  let descuento = Dinero({ amount: 0 })
  let impuesto = Dinero({ amount: 0 })
  let excento = Dinero({ amount: 0 })
  let total = Dinero({ amount: 0 })


  lineas.forEach((linea) => {
    if (parseInt(linea.cantidad) > 0 == false || parseInt(linea.precio) > 0 == false) return;

    const _subTotal = Dinero({ amount: parseInt(linea.precio * 100000) }).multiply(parseFloat(linea.cantidad || 0));
    const _descuento = _subTotal.percentage(linea.descuentoUnitario);
    const _subTotalConDescuento = _subTotal.subtract(_descuento);

    const _impuesto = _subTotalConDescuento.percentage(linea.impuestoUnitario);

    const _excento = _subTotalConDescuento.percentage(linea._excentoUnitario || 0);
    const _total = _subTotalConDescuento.add(_impuesto);

    descuento = descuento.add(_descuento);
    impuesto = impuesto.add(_impuesto);
    excento = excento.add(_excento)
    subTotal = subTotal.add(_subTotal);
    subTotalConDescuento = subTotalConDescuento.add(_subTotalConDescuento);
    total = total.add(_total);
  })

  return {
    subTotal: subTotal.toRoundedUnit(5),
    subTotalConDescuento: subTotalConDescuento.toRoundedUnit(5),
    total: total.toRoundedUnit(5),
    excento: excento.toRoundedUnit(5),
    impuesto: impuesto.toRoundedUnit(5),
    descuento: descuento.toRoundedUnit(5)
  }
}

module.exports = Math;