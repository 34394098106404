import React, { lazy } from "react";
import { makeTable } from "views/base/table";
import { makeEdit } from "views/base/edit";
import { makeCreate } from "views/base/create";
import onOrdenFieldChange from "custom/onChange/orden";
import onDocumentoFieldChange from "custom/onChange/documento";
import onProveedorFieldChange from "custom/onChange/proveedor";
import onFacturaCxPFieldChange from "custom/onChange/facturaCxP";
import onPagoCxPFieldChange from "custom/onChange/pagoCxP";
import onPagoDocumentoFieldChange from "custom/onChange/pagoDocumento";
import onAccountFieldChangeAccount from "custom/onChange/account";
import onBoletaFieldChange from "custom/onChange/boleta";

import { OnLogin } from "custom/onAction/usuario";

import DemoModal from "custom/modals/demo";
import MovimientoDevolverModal from "custom/modals/movimiento";
import SaldoModal from "custom/modals/saldo";

import ClienteFormatter from "custom/formatters/clienteFormatter";

const Cierre = lazy(() => import("custom/apps/cierre"));
const Firma = lazy(() => import("custom/apps/firma"));
const Emails = lazy(() => import("custom/apps/emails"));

//const PagoCxpTable = lazy(() => import('custom/apps/pagoCxP/table'));
//const SaldoTable = lazy(() => import('custom/apps/saldoTable'));
const Tablet = lazy(() => import("custom/apps/tablet"));
const AccountTable = lazy(() => import("custom/apps/accountTable"));
const JournalTable = lazy(() => import("custom/apps/journalTable"));
const BalanceTable = lazy(() => import("custom/apps/balanceTable"));

const OrdenLinea = lazy(() => import("custom/types/ordenLinea"));
const MovimientoInventario = lazy(() => import("custom/types/movimientoInventario"));
const JournalItem = lazy(() => import("custom/types/journalItem"));

//const FacturaCxPTable = lazy(() => import('custom/apps/facturaCxPTable'));
const GastoTable = lazy(() => import("custom/apps/gastoTable"));
//const PagoCxpCreate = lazy(() => import('custom/apps/pagoCxP/create'));

const ordenTypeMap = {
  ordenLinea: OrdenLinea
};

const boletaTypeMap = {
  movimientoInventario: MovimientoInventario
};

const JournalTypeMap = {
  journalItem: JournalItem
};

const menuItems = [
  { id: "tablet", default: "orden", title: "Tablet", icon: "TabletMac" },
  { id: "venta", default: "orden", title: "Venta", icon: "LocationCity" },
  { id: "credito", default: "saldo", title: "Credito", icon: "AccountBalance" },
  { id: "inventario", default: "boleta", title: "Inventarios", icon: "MoveToInbox" },
  { id: "cxp", default: "facturaCxP", title: "Cuentas por Pagar", icon: "Payment" },
  { id: "contabilidad", default: "account", title: "Contabilidad", icon: "AttachMoney" },
  { id: "auditoria", default: "registro", title: "Auditoria", icon: "Visibility" },
  { id: "administracion", default: "usuario", title: "Administracion", icon: "Settings" }
];

export const getMenu = () => {
  return Object.keys(AppMap).map(key => {
    return AppMap[key];
  });
};

export const AppMap = {
  mobile: {
    id: "mobile",
    title: "Mobile",
    icon: "TabletMac",
    routes: {
      orden: {
        title: "Orden",
        operations: { table: Tablet }
      }
    }
  },
  venta: {
    id: "venta",
    default: "orden",
    title: "Venta",
    icon: "LocationCity",
    routes: {
      orden: {
        title: "Orden",
        operations: {
          create: makeCreate(onOrdenFieldChange, ordenTypeMap),
          edit: makeEdit(onOrdenFieldChange, ordenTypeMap),
          table: makeTable(null, { ClienteFormatter: ClienteFormatter })
        }
      },
      cliente: { title: "Cliente" },
      documento: {
        title: "Documento",
        operations: {
          create: makeEdit(onDocumentoFieldChange),
          edit: makeEdit(onDocumentoFieldChange)
        }
      },
      movimientoInventario: {
        title: "Movimientos",
        operations: { table: makeTable({ Devolver: MovimientoDevolverModal }) }
      },
      vendedor: { title: "Vendedor" },
      comisionHistorico: { title: "Comision" }
    }
  },
  credito: {
    id: "credito",
    default: "saldo",
    title: "Credito",
    icon: "AccountBalance",
    routes: {
      cheque: { title: "Cheque" },
      saldo: { title: "Saldo", operations: { table: makeTable({ Pagar: SaldoModal }) } },
      pagoDocumento: {
        title: "Recibo",
        operations: {
          create: makeCreate(onPagoDocumentoFieldChange),
          edit: makeEdit(onPagoDocumentoFieldChange)
        }
      },
      lineaPagoDocumento: { title: "Pago" }
    }
  },
  inventario: {
    id: "inventario",
    default: "boleta",
    title: "Inventarios",
    icon: "MoveToInbox",
    routes: {
      despacho: {
        title: "Despacho"
      },
      boleta: {
        title: "Boleta",
        operations: {
          create: makeCreate(onBoletaFieldChange, boletaTypeMap),
          edit: makeEdit(onBoletaFieldChange, boletaTypeMap)
        }
      },
      producto: { title: "Producto" },
      precio: { title: "Precio" },
      descuentoGrupo: { title: "Descuento Grupo" },
      descuentoCliente: { title: "Descuento Cliente" },
      transporte: { title: "Transporte" }
    }
  },
  cxp: {
    id: "cxp",
    default: "facturaCxP",
    title: "Cuentas por Pagar",
    icon: "Payment",
    routes: {
      registroRecibido: { title: "Recibido" },
      gasto: { title: "Gasto", operations: { table: GastoTable } },
      facturaCxP: {
        title: "Facturas",
        operations: { create: makeCreate(onFacturaCxPFieldChange), edit: makeEdit(onFacturaCxPFieldChange) }
      },

      pagoCxP: {
        title: "Pago",
        operations: {
          create: makeCreate(onPagoCxPFieldChange),
          edit: makeEdit(onPagoCxPFieldChange)
        }
      },
      proveedor: { title: "Proveedor", operations: { create: makeCreate(onProveedorFieldChange) } }
    }
  },
  contabilidad: {
    id: "contabilidad",
    default: "account",
    title: "Contabilidad",
    icon: "AttachMoney",
    routes: {
      account: {
        title: "Cuenta",
        operations: {
          table: AccountTable,
          create: makeCreate(onAccountFieldChangeAccount),
          edit: makeEdit(onAccountFieldChangeAccount)
        },
        journal: {
          title: "Movimientos",
          operations: {
            table: JournalTable,
            create: makeCreate(null, JournalTypeMap),
            edit: makeEdit(null, JournalTypeMap)
          }
        },
        registroCuentaContable: { title: "Balance", operations: { table: BalanceTable } },
        bank: { title: "Bancos" }
      }
    }
  },
  auditoria: {
    id: "auditoria",
    default: "registro",
    title: "Auditoria",
    icon: "Visibility",
    routes: {
      registro: { title: "Registro" },
      costoHistoricoValor: { title: "Valor" },
      costoHistorico: { title: "Costos" },
      verProducto: { title: "Visualización Productos" },
      cierre: { title: "Cierre", operations: { table: Cierre } },
      registroContinuo: { title: "Registro Continuo" }
    }
  },
  administracion: {
    id: "administracion",
    default: "usuario",
    title: "Administracion",
    icon: "Settings",
    routes: {
      usuario: { title: "Usuarios", operations: { table: makeTable(null, null, { Login: OnLogin }) } },
      firmaDigital: { title: "Configuración", operations: { table: Firma } },
      log: { title: "Log" }
    }
  }
};
