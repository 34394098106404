import React from "react";

import ReactDOM from 'react-dom';

import DataGrid from 'react-data-grid';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import FileUpload from "components/CustomUpload/fileUpload";
import MoreHoriz from '@material-ui/icons/MoreHoriz';



// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// Custom Formatter component
class FileFormatter extends React.Component {

  state = {
    open: false,
  };


  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleComplete = (fileKey) => {
    this.setState({ open: false });
    this.props.handleUpdate({
      cellKey: this.props.column.key,
      updated: { [this.props.column.key]: fileKey },
      fromRowId: this.props.getRow(this.props.rowIdx).id
    })

  }


  renderModal() {
    var row = this.props.row;
    return <div>
      <Dialog
        open={this.state.open}
        TransitionComponent={Transition}

        onClose={this.handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"Ver detalles de un documento"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">

          </DialogContentText>

          <FileUpload
            item={this.props.row}
            fileKey={row[this.props.column.fileKey]}
            route={this.props.column.route}
            onComplete={this.handleComplete}
            onClose={this.handleClose}
            label="Cargar"
          />

        </DialogContent>
        <DialogActions>

          <Button onClick={this.handleClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  }

  renderIcon() {
    return this.props.value;
  }

  render() {
    const { classes } = this.props;

    return (
      <div  >
        {this.renderModal()}
        <IconButton onClick={this.handleClickOpen} color="primary" aria-label="Ver detalles">
          <MoreHoriz />
        </IconButton>
        {this.props.value}

      </div>
    )
  }
}


export default function (column, reload, getRow, handleUpdate) {
  return class extends React.Component {
    render() {

      return <FileFormatter handleUpdate={handleUpdate} reload={reload} getRow={getRow} column={column} {...this.props} />
    }
  }
}
function Transition(props) {
  return <Slide direction="up" {...props} />;
}



