import Finance from "redux/helpers/finance";
import { onFieldChange, onFieldsChange } from "redux/reducers/form";
import { alert } from "redux/reducers/header";
import Dinero from 'dinero.js';

Dinero.globalLocale = 'es-CR';
Dinero.defaultPrecision = 5;





const onChange = (_this, formId, name, value, extra) => {

  var form = _this.props.form.item;
  if (["clienteId"].indexOf(name) > -1) {
    if (_this.props.form.item.clienteId) return _this.props.dispatch(alert("El cliente no se puede cambiar, para corregir este campo debe crear otra orden."))

    return _this.props.dispatch(onFieldsChange(formId, {
      cedula: extra.cedula,
      emails: extra.correoDocumentosElectronicos,
      transporteId: extra.transporteId || 1,
      clienteId: value, plazo: extra.creditoPlazo || 0
    }));

  }
  else if (["ordenLinea"].indexOf(name) > -1) {
    value.forEach((linea) => {
      linea.excentoPorcentaje = _this.props.form.item.excentoPorcentaje || 0;
      _setImpuestos(linea);
    })
    return _this.props.dispatch(onFieldsChange(formId, { ...Finance.ordenTotal(value), ordenLinea: value }));
  }

  else if (["excentoPorcentaje"].indexOf(name) > -1) {

    if (!_this.props.form.item.excentoTipo) return _this.props.dispatch(alert("debe completa el campo tipo primero"))
    if (!_this.props.form.item.excentoNumero) return _this.props.dispatch(alert("debe completa el campo numero primero"))
    if (!_this.props.form.item.excentoInstitucion) return _this.props.dispatch(alert("debe completa el institucion tipo primero"))
    if (!_this.props.form.item.excentoFecha) return _this.props.dispatch(alert("debe completa el campo fecha primero"))

    _this.props.dispatch(onFieldChange(formId, name, value));
    _this.props.dispatch(onFieldChange(formId, "especial", true));


    if (_this.props.form.item.ordenLinea) {
      _this.props.form.item.ordenLinea.forEach((linea) => { _setImpuestos(linea, value) })
      _this.props.dispatch(onFieldsChange(formId, { ...Finance.ordenTotal(_this.props.form.item.ordenLinea), ordenLinea: _this.props.form.item.ordenLinea }));
    }
  }
  else return _this.props.dispatch(onFieldChange(formId, name, value));
}


const _setImpuestos = (linea, excentoPorcentaje) => {
  if (excentoPorcentaje) linea.excentoPorcentaje = parseFloat(excentoPorcentaje);

  linea._impuestoUnitario = linea._impuestoUnitario ? parseInt(linea._impuestoUnitario) : parseInt(linea.impuestoUnitario); //reset impuesto unitario

  var excentoPorcentaje = new Dinero({ amount: parseInt((linea.excentoPorcentaje || 0) * 100000) }); //excentoPorcentaje for Dinero
  var impuestoUnitarioPercent = Dinero({ amount: 100 * 100000 }).subtract(excentoPorcentaje).toRoundedUnit(2);; // impuesto unitario 100 - Excento

  linea._excentoUnitario = Dinero({ amount: parseInt(linea._impuestoUnitario) * 100000 }).percentage(linea.excentoPorcentaje).toRoundedUnit(2);
  linea.impuestoUnitario = Dinero({ amount: parseInt(linea._impuestoUnitario) * 100000 }).percentage(impuestoUnitarioPercent).toRoundedUnit(2);
}

export default onChange;