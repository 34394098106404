import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import red from "@material-ui/core/colors/red";
import FilterList from "@material-ui/icons/FilterList";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AddIcon from "@material-ui/icons/Add";

import Button from "@material-ui/core/Button";
import blue from "@material-ui/core/colors/blue";

import { onLoadMetaData, selectMetadataByType } from "redux/reducers/metadata";
import {
  selectDataByType,
  onLoadData,
  onClearData,
  onReloadData,
  onSave,
  onAction
} from "redux/reducers/data";
import {
  selectFilters,
  changeLimit,
  selectLimit,
  onResetFilters
} from "redux/reducers/table";
import { alert } from "redux/reducers/header";

import { connect } from "react-redux";
import SmartTable from "components/SmartTable/Table";
import ActionMenu from "./actionMenu";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import PrintComponent from "./print";

import Icon from "@material-ui/core/Icon";
import DeleteIcon from "@material-ui/icons/Delete";
import CloudDownload from "@material-ui/icons/NetworkCheck";
import Badge from "@material-ui/core/Badge";

import asyncComponent from "views/base/async";

import SumComponent from "components/SmartTable/Sum";

import {
  unSubscribeToStandardChannel,
  subscribeToStandardChannel,
  subscribeToMessage
} from "redux/reducers/socket";

const GraphComponentAsync = asyncComponent(() => import("./graph"));

const BatchComponentAsync = asyncComponent(() => import("./batch"));

export const styles = theme => ({
  root: {
    marginTop: "-8px",
    backgroundColor: "#fff"
  },
  main: {
    margin: theme.spacing.unit * 5,
    marginTop: 0,
    marginBottom: 10
  },
  toolbar: {
    margin: theme.spacing.unit * 4,
    backgroundColor: "#fff",
    marginTop: "10px",
    marginBottom: 0
  },
  button: {
    margin: theme.spacing.unit * 1
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0
  },
  warningButton: {
    margin: 0
  },
  customBadge: {
    top: -4,
    right: -15,
    // The border color match the background color.
    border: `2px solid ${
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[900]
    }`
  },
  badgeLabel: {
    marginLeft: 25
  },
  badge: {
    top: -9,
    right: -20,
    minWidth: 25,
    minHeight: 25,
    width: "auto",
    height: "auto",
    padding: 1,
    // The border color match the background color.
    border: `2px solid ${
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[900]
    }`
  }
});

export function makeTable(modelMap, formatters, actionMap) {
  var Table = class Any extends BaseTable {
    getModelMap() {
      return modelMap || {};
    }

    getActionMap() {
      return actionMap || {};
    }

    getCustomFormatters() {
      return formatters || {};
    }
  };

  const mapStateToProps = (state, ownProps) => {
    return {
      metadata: selectMetadataByType(state, ownProps.metadataType),
      dataRows: selectDataByType(state, ownProps.metadataType),
      limit: selectLimit(state, ownProps.metadataType)
    };
  };

  const ConnectedTable = connect(mapStateToProps)(Table);

  return withStyles(styles, { withTheme: true })(ConnectedTable);
}

class BaseTable extends React.Component {
  state = {
    selectedItems: [],
    dialogOpen: false,
    dialogPrint: false,
    dialogBatch: false,
    printing: false,
    printColumns: []
  };

  componentDidMount() {
    this._componentDidMount();
  }

  componentWillReceiveProps(nextProps) {
    this._componentWillReceiveProps(nextProps);
  }

  _componentDidMount() {
    this.props.dispatch(
      onLoadMetaData(this.props.metadataType, null, {
        inProgress: true,
        source: "table"
      })
    );
    this.props.dispatch(subscribeToStandardChannel(this.props.metadataType));
  }

  _componentWillReceiveProps(nextProps) {}

  componentWillUnmount() {
    this.props.dispatch(unSubscribeToStandardChannel(this.props.metadataType));
  }

  reload() {
    this.props.dispatch(onReloadData(this.props.metadataType));
  }

  updateSelectedRows = rows => {
    this.setState({ selectedItems: rows });
  };

  onCrear(items) {
    this.props.history.push(this.props.location.pathname + "/create");
  }

  onBorrar(items) {
    var ids = items.map(item => item.id);
    this.props.dispatch(onAction(this.props.metadataType, "destroy", ids));
  }

  getModelMap() {
    return {};
  }

  getActionMap() {
    return {};
  }

  onCustomAction(action) {
    return function(items) {
      var ids = items.map(item => item.id);

      this.props
        .dispatch(
          onAction(this.props.metadataType, action.title.toLowerCase(), ids)
        )
        .then(response => {
          if (action.then == "openUrl") {
            window.open(response.url);
          }
        });
    };
  }

  handleMenuClick = action => {
    var actionMap = this.getActionMap();
    var actionName = action.title;

    if (
      action.constraint &&
      action.constraint == "single" &&
      this.state.selectedItems.length > 0
    )
      return this.props.dispatch(
        alert(
          "Este boton solo permite una fila selecionada y hay  " +
            this.state.selectedItems +
            " activas"
        )
      );
    if (
      action.constraint &&
      (action.constraint == "single" || action.constraint == "multiple") &&
      this.state.selectedItems.length == 0
    )
      return this.props.dispatch(alert("Debe escoger al menos una column"));

    var customAction = this[`on${actionName}`];

    if (actionMap[actionName]) {
      customAction = actionMap[actionName];
      customAction(this, this.state.selectedItems);
      return this.setState({ selectedItems: [] });
    } else if (!customAction) customAction = this.onCustomAction(action);

    customAction.bind(this)(this.state.selectedItems, action);
    this.setState({ selectedItems: [] });
  };

  handleLimitChange = e => {
    this.props.dispatch(changeLimit(this.props.metadataType));
  };

  handleRefreshClick = e => {
    this.setState({ selectedItems: [] });
    this.props.dispatch(onReloadData(this.props.metadataType));
  };

  handleResetClick = e => {
    this.setState({ selectedItems: [] });
    this.props.dispatch(onResetFilters(this.props.metadataType));
  };

  handleBatchClick = e => {
    this.setState({ dialogBatch: true });
  };

  onShowModal = (name, params, size) => {
    this.setState({
      dialogOpen: true,
      dialogName: name,
      dialogParams: params,
      dialogSize: size
    });
  };

  onCloseModal = () => {
    this.setState({
      selectedItems: [],
      dialogOpen: false,
      dialogName: null,
      dialogParams: null,
      dialogSize: null
    });
  };

  getTableProps() {
    return {
      updateSelectedRows: this.updateSelectedRows
    };
  }

  getCellActions(column, row) {}

  onPrint = e => {
    this.setState({ dialogPrint: true });
  };

  onClosePrint = e => {
    this.setState({ dialogPrint: false });
  };

  onGraph = e => {
    this.setState({ dialogGraph: true });
  };

  onCloseGraph = e => {
    this.setState({ dialogGraph: false });
  };

  onCloseBatch = e => {
    this.setState({ dialogBatch: false });
  };

  renderBatchComponent() {
    if (!this.state.dialogBatch) return null;
    return (
      <BatchComponentAsync
        metadata={this.props.metadata}
        dataRows={this.props.dataRows}
        dialogOpen={this.state.dialogBatch}
        onCloseBatch={this.onCloseBatch}
      />
    );
  }

  renderPrintComponent() {
    if (!this.state.dialogPrint) return null;
    return (
      <PrintComponent
        metadata={this.props.metadata}
        dataRows={this.props.dataRows}
        dialogOpen={this.state.dialogPrint}
        onClosePrint={this.onClosePrint}
      />
    );
  }

  renderGraphComponent() {
    if (!this.state.dialogGraph) return null;
    return (
      <GraphComponentAsync
        metadata={this.props.metadata}
        dataRows={this.props.dataRows}
        dialogOpen={this.state.dialogGraph}
        onClosePrint={this.onCloseGraph}
      />
    );
  }

  handleModalClick = action => {
    this.setState({ dialogName: action.title, dialogOpen: true });
  };

  renderModal() {
    const { classes } = this.props;
    if (!this.state.dialogOpen) return null;
    var Modal = this.getModelMap()[this.state.dialogName];

    if (!Modal) return null;

    return (
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}
        maxWidth={this.state.dialogSize || "md"}
        fullScreen={this.state.dialogSize == "fullScreen"}
        open={this.state.dialogOpen}
        onClose={this.onCloseModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <div>
            <Modal
              dispatch={this.props.dispatch}
              items={this.state.selectedItems}
              onCloseModal={this.onCloseModal}
            />
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  getCustomFormatters() {
    return {};
  }

  renderTable() {
    if (!this.props.metadata || !this.props.dataRows) return null;

    return (
      <SmartTable
        customFormatters={this.getCustomFormatters()}
        onShowModal={this.onShowModal}
        getCellActions={this.getCellActions}
        updateSelectedRows={this.updateSelectedRows}
        selectedIndexes={this.state.selectedItems}
        {...this.props}
        tableProps={this.getTableProps()}
      />
    );
  }

  renderLimit() {
    const { classes, theme } = this.props;
    var rows = this.props.dataRows || [];

    if (!this.props.limit)
      return (
        <Grid container>
          <Grid item>
            <Badge
              classes={{ badge: classes.badge }}
              badgeContent={rows.length}
              color="primary"
            >
              <CloudDownload className={classes.badgeButton} fontSize="small" />
            </Badge>
          </Grid>
          <Grid item>
            <span className={classes.badgeLabel}>Filas Cargadas</span>
          </Grid>
        </Grid>
      );

    return (
      <Grid container>
        <Grid item>
          <IconButton
            onClick={this.handleLimitChange}
            color="secondary"
            className={classes.warningButton}
          >
            <CloudDownload fontSize="small" />
          </IconButton>
        </Grid>
        <Grid item>
          <div>
            {" "}
            Se a cargó una muestra de {rows.length} líneas. <br />
            Cargue hasta {this.props.metadata.count} líneas
          </div>
        </Grid>
      </Grid>
    );
  }

  render() {
    const { classes, theme } = this.props;
    if (!this.props.metadata) return null;
    var limit = this.props.metadata.limit || 300;
    if (this.props.limit) limit = this.props.metadata.maxLimit || 10000;

    var actions = [];
    if (this.props.metadata && this.props.metadata.actions)
      actions = this.props.metadata.actions;
    return (
      <div className={classes.root}>
        <div className={classes.toolbar}>
          <CardHeader
            action={
              <Grid container>
                <ActionMenu
                  selectedItems={this.state.selectedItems}
                  handleRefreshClick={this.handleRefreshClick}
                  handleResetClick={this.handleResetClick}
                  handleBatchClick={this.handleBatchClick}
                  handleItemClick={this.handleMenuClick}
                  handleModalClick={this.handleModalClick.bind(this)}
                  onPrint={this.onPrint}
                  onGraph={this.onGraph}
                  actions={actions || []}
                  modals={this.props.metadata.modals || []}
                  batch={this.props.metadata.batch}
                />
                ,
              </Grid>
            }
            title={
              <Typography variant="title">
                {this.props.metadata.title}
              </Typography>
            }
            subheader={
              <Grid container>
                <Grid row>{this.renderLimit()}</Grid>

                <Grid row>
                  <SumComponent
                    metadata={this.props.metadata}
                    metadataType={this.props.metadataType}
                    rows={this.props.dataRows}
                  />
                </Grid>
              </Grid>
            }
          />
        </div>

        <div className={classes.main}>
          {this.renderModal()}
          {this.renderBatchComponent()}
          {this.renderPrintComponent()}
          {this.renderGraphComponent()}

          {this.renderTable()}
        </div>
      </div>
    );
  }
}

export default BaseTable;
