import React from 'react';
import PropTypes from 'prop-types';
import { shapes } from 'react-data-grid';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import { onFilter, selectFilterByKey } from "redux/reducers/table";
import { connect } from "react-redux";
import ViewAgenda from '@material-ui/icons/ViewAgenda';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import withStyles from "@material-ui/core/styles/withStyles";
import style from "./FilterStyles";


const { ExcelColumn } = shapes;
const RuleType = {
  Number: 1,
  Range: 2,
  GreaterThen: 3,
  LessThen: 4
};

const mapStateToProps = (store, props) => {
  return {
    filter: selectFilterByKey(store, props.column.metadataType, props.column.key)
  }
}


class NumericFilter extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.getRules = this.getRules.bind(this);
  }


  _filterValues(row, columnFilter, columnKey) {

  }

  _filterValues(row, columnFilter, columnKey) {
    if (columnFilter.filterTerm == null) {
      return true;
    }
    let result = false;
    // implement default filter logic
    let value = parseInt(row[columnKey], 10);
    for (let ruleKey in columnFilter.filterTerm) {
      if (!columnFilter.filterTerm.hasOwnProperty(ruleKey)) {
        continue;
      }

      let rule = columnFilter.filterTerm[ruleKey];

      switch (rule.type) {
        case RuleType.Number:
          if (rule.value === value) {
            result = true;
          }
          break;
        case RuleType.GreaterThen:
          if (rule.value <= value) {
            result = true;
          }
          break;
        case RuleType.LessThen:
          if (rule.value >= value) {
            result = true;
          }
          break;
        case RuleType.Range:
          if (rule.begin <= value && rule.end >= value) {
            result = true;
          }
          break;
        default:
          // do nothing
          break;
      }
    }
    return result;
  }

  getRules(value) {
    let rules = [];
    if (value === '') {
      return rules;
    }
    // check comma
    let list = value.split(',');
    if (list.length > 0) {
      // handle each value with comma
      for (let key in list) {
        if (!list.hasOwnProperty(key)) {
          continue;
        }

        let obj = list[key];
        if (obj.indexOf('-') > 0) { // handle dash
          let begin = parseInt(obj.split('-')[0], 10);
          let end = parseInt(obj.split('-')[1], 10);
          rules.push({ type: RuleType.Range, begin: begin, end: end });
        } else if (obj.indexOf('>') > -1) { // handle greater then
          let begin = parseInt(obj.split('>')[1], 10);
          rules.push({ type: RuleType.GreaterThen, value: begin });
        } else if (obj.indexOf('<') > -1) { // handle less then
          let end = parseInt(obj.split('<')[1], 10);
          rules.push({ type: RuleType.LessThen, value: end });
        } else { // handle normal values
          let numericValue = parseInt(obj, 10);
          rules.push({ type: RuleType.Number, value: numericValue });
        }
      }
    }
    return rules;
  }

  handleKeyPress(e) { // Validate the input
    let regex = '>|<|-|,|([0-9])';
    let result = RegExp(regex).test(e.key);
    if (result === false) {
      e.preventDefault();
    }
  }

  handleChange = (e) => {
    if (this.props.column.groupOnly) return;

    let value = e.target.value;
    var key = this.props.column.key;

    var filterValue = [`${this.props.metadataType}.${key}`, "=", value];
    if (this.props.column.select) filterValue[0] = key;


    this.props.onChange({
      [this.props.column.key]: { metadataType: this.props.metadataType, column: this.props.column, textValue: value, filterValue: filterValue }
    });

  }

  _handleChange(e) {
    let value = e.target.value;
    let filters = this.getRules(value);
    this.props.onChange({
      filterTerm: (filters.length > 0 ? filters : null),
      column: this.props.column,
      rawValue: value,
      filterValues: this.filterValues
    });

    this.props.dispatch(onFilter({ [this.props.column.key]: value }))
  }

  render() {
    const { classes } = this.props;

    let inputKey = 'header-filter-' + this.props.column.key;
    let columnStyle = {
      float: 'left',
      marginRight: 5,
      maxWidth: '80%'
    };
    let badgeStyle = {
      cursor: 'help'
    };

    let filterValue = this.props.filter || { textValue: "" };

    return <div style={columnStyle}>
      <Grid container spacing={1} alignItems="flex-end">
        <Grid item xs={10}>
          <Tooltip title="Input Methods: Range (x-y), Greater Then (>x), Less Then (<y)">
            <TextField
              className={classes.textInput}
              value={filterValue.textValue}
              onChange={this.handleChange}
              onKeyPress={this.handleKeyPress}
              InputProps={{
                classes: {
                  underline: classes.underline,
                  root: classes.inputRoot
                }
              }}
            />
          </Tooltip>
        </Grid>

        <Grid item xs={2}>

        </Grid>

      </Grid>
    </div>

  }
}

NumericFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  column: PropTypes.shape(ExcelColumn)
};

const NumericFilterWithStyles = withStyles(style)(connect(mapStateToProps)(NumericFilter));


export default function (metadataType) {
  return class extends React.Component {
    render() {

      return <NumericFilterWithStyles metadataType={metadataType} {...this.props} />
    }
  }
}
