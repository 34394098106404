import React, { Suspense } from "react";
import queryString from "query-string";
import Toolbar from "../base/toolbar";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { makeTable } from "views/base/table";
import { makeEdit } from "views/base/edit";
import { makeCreate } from "views/base/create";

import Popper from "@material-ui/core/Popper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Feed from "components/Feed";
import { subscribeToDefaultChannels } from "redux/reducers/socket";

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: "100vw"
  },
  paper: {
    height: 140,
    width: 100
  },
  control: {
    padding: theme.spacing.unit * 2
  },
  demo: {
    paddingTop: theme.spacing.unit * 4,
    padding: "10px"
  },
  actions: {
    marginTop: theme.spacing.unit * 4
  },

  notificiationPaper: {
    padding: 0,
    margin: 30
  }
});

class BaseRoute extends React.Component {
  componentDidMount() {
    this.props.dispatch(subscribeToDefaultChannels());
  }

  getComponentFromPath = (metadataType, operation, id) => {
    var queryParams = { source: operation };

    if (["table"].indexOf(operation) > -1) queryParams.inProgress = true;
    else if (["create"].indexOf(operation) > -1) queryParams = null;
    else if (["edit"].indexOf(operation) > -1) queryParams.id = id;

    var Route = this.props.menu.routes[metadataType].operations;
    if (!Route || !Route[operation]) {
      if (operation == "table") return makeTable();
      if (operation == "edit") return makeEdit();
      if (operation == "create") return makeCreate();
    }
    return Route[operation];
  };

  getComponent(metadataType, operation, props) {
    if (!metadataType) metadataType = this.props.baseMetadataType;
    var Operation;
    var parsedQuery = queryString.parse(props.location.search);
    var formId = props.formId || parsedQuery.formId || Math.random();

    var Operation = this.getComponentFromPath(
      metadataType,
      operation,
      props.match.params.id
    );

    return (
      <Operation
        id={props.match.params.id}
        getComponentFromPath={this.getComponentFromPath}
        formId={formId || Math.random()}
        metadataType={metadataType}
        {...props}
      />
    );
  }

  home = props => {
    return this.getComponent(props.match.params.metadataType, "table", props);
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Toolbar {...this.props} tabs={this.props.menu.routes} />
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route
              path={`/${this.props.namespace}/:metadataType/:operation/:id`}
              render={props =>
                this.getComponent(
                  props.match.params.metadataType,
                  props.match.params.operation,
                  props
                )
              }
            />
            <Route
              path={`/${this.props.namespace}/:metadataType/:operation`}
              render={props =>
                this.getComponent(
                  props.match.params.metadataType,
                  props.match.params.operation,
                  props
                )
              }
            />
            <Route
              path={`/${this.props.namespace}/:metadataType`}
              render={props => this.home(props)}
            />
            <Route
              path={`/${this.props.namespace}`}
              render={props => <div />}
            />
          </Switch>
        </Suspense>

        <Feed feeds={this.props.feeds} />
      </div>
    );
  }
}

const mapStateToProps = (store, props) => {
  return {};
};

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps)(BaseRoute)
);
