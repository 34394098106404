import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import red from '@material-ui/core/colors/red';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import Button from '@material-ui/core/Button';
import blue from '@material-ui/core/colors/blue';


import { onCreateForm, onFieldChange, onUpdateField, onUpdateItem, selectFormById } from "redux/reducers/form";
import { onLoadData, onLoadDataById, selectDataByType, selectDataByTypeAndId, onSave, onAction } from "redux/reducers/data";
import { onLoadMetaData, selectMetadataByType } from "redux/reducers/metadata";
import { simpleApi, selectApiResponseByFormId } from "redux/reducers/api";


import { connect } from "react-redux";
import SmartForm from "components/SmartForm/Form";
import Checkbox from "@material-ui/core/Checkbox";

import moment from "moment";

// material-ui icons
import Assignment from "@material-ui/icons/Assignment";
import PanTool from "@material-ui/icons/PanTool";
import Edit from "@material-ui/icons/Edit";
import Check from "@material-ui/icons/Check";
import CheckCircle from "@material-ui/icons/CheckCircle";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Add from "@material-ui/icons/Add";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import AppBar from '@material-ui/core/AppBar';

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Hidden from "@material-ui/core/Hidden";
import { throttle, debounce } from "throttle-debounce";
import { Switch, Route, Redirect } from "react-router-dom";

// material-ui components
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";



import CircularProgress from '@material-ui/core/CircularProgress';
import CustomInput from "components/CustomInput/CustomInput";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import CardText from "components/Card/CardText.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Tooltip from '@material-ui/core/Tooltip';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import grey from '@material-ui/core/colors/grey';

import Form from "./form";

export const styles = theme => ({
  root: {
    marginTop: "-8px",
  },
  main: {
    margin: theme.spacing.unit * 12,
    marginTop: 0
  },
  "$dialog main": {
    margin: theme.spacing.unit * 1,
  },
  toolbar: {
    margin: theme.spacing.unit * 4,
    marginTop: "10px",
    marginBottom: 0
  },
  button: {
    margin: theme.spacing.unit * 1
  },
  dialog: {
    backgroundColor: grey[100]
  }
});

export function makeCreate(onFieldChangeOverrride, typeMap) {

  var Create = class Any extends BaseCreate {

    onFieldChange = (formId, name, value, extra) => {
      if (onFieldChangeOverrride) onFieldChangeOverrride(this, formId, name, value, extra);
      else this._onFieldChange(formId, name, value, extra);
    }

    getTypeMap() {
      return typeMap || {}
    }

  }

  const mapStateToProps = (state, ownProps) => {
    return {
      form: selectFormById(state, ownProps.formId),
      metadata: selectMetadataByType(state, ownProps.metadataType),
      dataRows: selectDataByType(state, ownProps.metadataType)
    }
  }

  const ConnectedCreate = connect(
    mapStateToProps
  )(Create)

  return withStyles(styles, { withTheme: true })(ConnectedCreate);
}

class BaseCreate extends Form {

  constructor() {
    super();
    this.state = {
      dialogOpen: false,
      loadingMetadata: false
    }
  }

  _componentDidMount() {
    if (!this.props.form) this.props.dispatch(onCreateForm({ id: this.props.formId, type: this.props.metadataType }))

    if (this.props.metadata) this.getDefault(this.props);

    this.props.dispatch(onLoadMetaData(this.props.metadataType, null, { source: "create" }));

  }

  componentDidMount() {
    return this._componentDidMount();
  }

  _componentWillReceiveProps(nextProps) {

    if (!this.props.metadata && nextProps.metadata || !this.props.form && nextProps.form) {
      this.getDefault(nextProps);
    }
  }

  getDefault(props) {
    if (!props.form || !props.metadata) return;

    var defaultItem = { ...props.form.item };
    Object.keys(props.metadata.properties).forEach((key) => {
      var property = props.metadata.properties[key];
      if (defaultItem[property.key] == null && (property.default || property.default == 0 || property.default == false)) {
        if (property.default == "TODAY") defaultItem[property.key] = moment().format("YYYY-MM-DD");
        else defaultItem[property.key] = property.default;
      }
    })
    props.dispatch(onUpdateItem(props.formId, defaultItem, props.metadata))
  }

  componentWillReceiveProps(nextProps) {
    return this._componentWillReceiveProps(nextProps);
  }

  onSave = () => {
    var item = {};
    var keys = Object.keys(this.props.form.item)
    keys.forEach((key) => {
      if (key.indexOf("__") == 0) return;
      item[key] = this.props.form.item[key];
    })

    return this.props.dispatch(onSave(this.props.metadataType, item))
      .then((result) => {
        if (this.props.history) this.props.history.push(`/${this.props.metadata.namespace}/${this.props.metadataType}`);
        else return this.props.onClose(result);
        return result;
      })
      .catch(function (e) {
        console.log(e);
      })
  }





}

export default BaseCreate;
