import React from 'react';
import PropTypes from 'prop-types';
import { shapes } from 'react-data-grid';
import withStyles from "@material-ui/core/styles/withStyles";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import moment from 'moment';
import { onFilter, selectFilterByKey, selectGroupByKey, changeGroup } from "redux/reducers/table";
import GridOff from '@material-ui/icons/GridOff';
import { connect } from "react-redux";
import ViewAgenda from '@material-ui/icons/ViewAgenda';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Blue from '@material-ui/core/colors/blue';
import style from "./FilterStyles";

const { ExcelColumn } = shapes;

const mapStateToProps = (store, props) => {
  return {
    filter: selectFilterByKey(store, props.column.metadataType, props.column.key),
    grouped: selectGroupByKey(store, props.column.metadataType, props.column.key)

  }
}


class DateFilter extends React.Component {

  state = {
    open: false,
    textValue: "",
    showRange: false,
    start: moment(),
    end: moment()
  };

  handleClickOpen = () => {
    if (this.props.column.groupOnly) return;

    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleRemove = () => {
    this.setState({ open: false, start: moment(), end: moment(), textValue: "" });
    this.props.onChange({
      [this.props.column.key]: { metadataType: this.props.metadataType, column: this.props.column, textValue: "", filterValue: null }
    });
  };

  handleFilter = (e) => {
    if (!this.state.end || !this.state.start) return;
    var dateStart = this.state.start;
    var dateEnd = this.state.end;

    this.setState({ open: false })

    var key = this.props.column.key;
    var textValue = `${dateStart.format("MM/DD")} <> ${dateEnd.format("MM/DD")}`;


    var filterValue = [`${this.props.metadataType}.${key}`, "BETWEEN", [this.state.start.format("YYYY-MM-DD"), this.state.end.format("YYYY-MM-DD")]];
    if (this.props.column.select) filterValue[0] = key;

    if (!this.state.showRange) {
      textValue = `${dateStart.format("MM/DD/YYYY")}`;
      filterValue = [`${this.props.metadataType}.${key}`, "=", this.state.start.format("YYYY-MM-DD")];
      if (this.props.column.select) filterValue[0] = key;

    }

    this.props.onChange({
      [this.props.column.key]: { metadataType: this.props.metadataType, column: this.props.column, textValue: textValue, filterValue: filterValue }
    });

  }


  onChangeStart = (e) => {
    var value = e.currentTarget.value;
    this.setState({ start: moment(value) });
  }

  onChangeEnd = (e) => {
    var value = e.currentTarget.value;
    this.setState({ end: moment(value) });
  }

  onClick = () => {
    this.props.onChange({
      column: this.props.column,
      value: "GROUP"
    })
  }


  renderIconButton() {
    const { classes } = this.props;
    var icon;
    if (!this.props.grouped) icon = <ViewAgenda classes={{ root: classes.svg }} />
    else icon = <GridOff />

    return <IconButton className={classes.iconButton} color={this.props.grouped ? "primary" : "default"} size="sm" onClick={this.onClick}>
      {icon}
    </IconButton>
    return null;
  }

  onRangeClick = (e) => {
    this.setState({ showRange: !this.state.showRange })
  }

  renderSingleDateSelection() {
    const { classes } = this.props;
    return <TextField
      id="dateStart"
      label="Inicio"
      type="date"
      value={this.state.start.format("YYYY-MM-DD")}
      onChange={this.onChangeStart}
      className={classes.textField}
      InputLabelProps={{
        shrink: true,
      }}
    />
  }

  renderRangeDateSelection() {
    const { classes } = this.props;

    return <div>
      <TextField
        id="dateStart"
        label="Inicio"
        type="date"
        value={this.state.start.format("YYYY-MM-DD")}
        onChange={this.onChangeStart}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
      />

      <TextField
        id="dateEnd"
        label="Fin"
        type="date"
        onChange={this.onChangeEnd}
        value={this.state.end.format("YYYY-MM-DD")}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
      />

    </div>
  }

  renderDateSelection() {
    if (this.state.showRange == false) return this.renderSingleDateSelection();
    else return this.renderRangeDateSelection();
  }

  render() {
    const { classes } = this.props;

    let columnStyle = { maxWidth: '100%' };

    let filterValue = this.props.filter || { textValue: "" };

    return <div style={columnStyle}>
      <Grid container spacing={1} alignItems="flex-end">
        <Grid item xs={10}>
          <TextField
            classes={{
              root: classes.inputRoot
            }}
            value={filterValue.textValue}
            InputProps={{
              classes: {
                root: classes.inputRoot,
                underline: classes.underline,

              }
            }}
            className={classes.textInput}
            id="input-with-icon-grid"
            onClick={this.handleClickOpen} />
        </Grid>

        <Grid item xs={2}>
          {this.renderIconButton()}
        </Grid>

      </Grid>

      <Dialog
        open={this.state.open}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Filtro de Fecha</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Se mostraran los resultados inclusivos de ambas fechas.
            </DialogContentText>

          {this.renderDateSelection()}

        </DialogContent>
        <DialogActions>
          <Button onClick={this.onRangeClick} color="primary">
            {!this.state.showRange ? "Por  Rango" : "Por Día"}
          </Button>
          <Button onClick={this.handleRemove} color="secondary">
            Eliminar
            </Button>
          <Button onClick={this.handleFilter} color="primary">
            Filtrar
            </Button>
        </DialogActions>
      </Dialog>

    </div>
  }
}

DateFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  column: PropTypes.shape(ExcelColumn)
};

const DateFilterWithStyles = withStyles(style)(connect(mapStateToProps)(DateFilter));



export default function (metadataType) {
  return class extends React.Component {
    render() {

      return <DateFilterWithStyles metadataType={metadataType} {...this.props} />
    }
  }
}