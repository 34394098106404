import Finance from "redux/helpers/finance";
import { alert } from "redux/reducers/header";
import { onFieldChange, onFieldsChange } from "redux/reducers/form";



const onDocumentoFieldChange = (_this, formId, name, value, extra) => {

  var form = _this.props.form.item;
  if (["clienteId"].indexOf(name) > -1) {
    _this.props.dispatch(onFieldsChange(formId, { clienteId: value, plazo: extra.creditoPlazo || 0 }));
  }
  else if (["ordenLinea"].indexOf(name) > -1) {
    _this.props.dispatch(onFieldsChange(formId, { ...Finance.ordenTotal(value), ordenLinea: value }));
  }

  else _this.props.dispatch(onFieldChange(formId, name, value));
}

export default onDocumentoFieldChange
