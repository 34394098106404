import Ajax from "../helpers/ajax";
import { push } from "connected-react-router";
import * as Sentry from "@sentry/browser";

const init = {
  view: "",
  user: {},
  account: ""
};

export function onLogout() {
  window.localStorage.setItem("authorization", null);
  localStorage.clear();
  window.location.reload();
}

export function whoAmI() {
  return function(dispatch, getState) {
    if (!window.localStorage.getItem("authorization")) return;
    if (getState().user.user.id) return;

    return Ajax.post(dispatch, `crm/usuario/whoAmI`, {}).then(function(result) {
      dispatch(onWhoAmISuccess(result));
      Sentry.configureScope(scope => {
        scope.setUser({
          id: result.id,
          username: result.name,
          email: result.email
        });
      });
      return result;
    });
  };
}

export function onWhoAmISuccess(result) {
  return {
    type: "USER_WHOAMI_SUCCESS",
    payload: result
  };
}

export function onSelectApi(api) {
  window.localStorage.setItem("account", api);
  return {
    type: "USER_SET_ACCOUNT",
    payload: api
  };
}

export function onLogin(email) {
  return function(dispatch, getState) {
    if (!window.localStorage.getItem("account")) return;

    return Ajax.login(dispatch, `crm/login/login`, { email: email }).then(function(result) {
      window.localStorage.setItem("email", email);
      dispatch(onLoginSuccess(result));
      return result;
    });
  };
}

export function onCode(code) {
  return function(dispatch, getState) {
    return Ajax.login(dispatch, `crm/login/code`, { code: code }).then(function(result) {
      window.localStorage.setItem("authorization", result.authorization);
      dispatch(onCodeSuccess(result));
      window.location.reload();
      return result;
    });
  };
}

export function onLoginAs(id) {
  return function(dispatch, getState) {
    return Ajax.post(dispatch, `crm/login/loginAs`, { id: id }).then(function(result) {
      window.localStorage.setItem("authorization", result.authorization);
      window.location.reload();
      return result;
    });
  };
}

export function onLoginSuccess(type, result) {
  return {
    type: "USER_LOGIN_SUCCESS"
  };
}

export function onCodeSuccess(type, result) {
  return {
    type: "USER_CODE_SUCCESS"
  };
}

export default (state = init, action) => {
  switch (action.type) {
    case "USER_WHOAMI_SUCCESS": {
      return { ...state, user: action.payload.user };
    }

    case "AJAX_401": {
      return { ...state, view: "EMAIL" };
    }

    case "USER_LOGIN_SUCCESS": {
      return { ...state, view: "CODE" };
    }

    case "USER_CODE_SUCCESS": {
      return { ...state, view: null };
    }

    case "USER_SET_ACCOUNT": {
      return { ...state, account: action.payload };
    }
  }

  return state;
};
