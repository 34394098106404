import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
import LockOutline from "@material-ui/icons/Email";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import pagesStyle from "assets/jss/material-dashboard-pro-react/layouts/pagesStyle.jsx";
import { onLogin, onCode, onSelectApi } from "redux/reducers/user";
import TextField from "@material-ui/core/TextField";

import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Colibri from "assets/img/colibri.svg";
import bgImage from "assets/img/forest.jpg";
import red from "@material-ui/core/colors/red";

const style = {
  ...loginPageStyle,
  ...pagesStyle,
  cardLogin: {
    backgroundColor: "rgba(255,255,255,0.7)"
  },
  errorMessage: {
    color: red[600],
    marginLeft: 20
  }
};

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden"
    };
  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }

  state = {
    email: window.localStorage.getItem("email") || "",
    code: "",
    error: ""
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  onLogin = () => {
    this.props.dispatch(onLogin(this.state.email));
  };

  onCode = () => {
    this.props.dispatch(onCode(this.state.code));
  };

  onApiChange = event => {
    this.props.dispatch(onSelectApi(event.target.value));
  };

  onEmailChange = e => {
    this.setState({ email: e.currentTarget.value });
  };

  onCodeChange = e => {
    this.setState({ code: e.currentTarget.value });
  };

  onFormClick = e => {
    if (this.props.user.view == "EMAIL")
      this.props
        .dispatch(onLogin(this.state.email))
        .then(() => {
          this.setState({ error: "" });
        })
        .catch(() => {
          this.setState({ error: "No encontramos el email" });
        });
    else
      this.props
        .dispatch(onCode(this.state.code))
        .then(() => {
          this.setState({ error: "" });
        })
        .catch(() => {
          this.setState({ error: "El codigo es incorrecto" });
        });
  };

  renderEmail() {
    const { classes } = this.props;

    if (this.props.user.view != "EMAIL") return null;
    return (
      <div className={classes.root}>
        <FormControl fullWidth className={classes.formControl}>
          <InputLabel htmlFor="age-simple">Cuenta</InputLabel>
          <Select
            value={this.props.user.account}
            onChange={this.onApiChange}
            inputProps={{
              name: "api",
              id: "api-simple"
            }}>
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={"rodco"}>Rodco</MenuItem>
            <MenuItem value={"positiveion"}>Positive Ion</MenuItem>
            <MenuItem value={"mardaro"}>Mardaro</MenuItem>
            <MenuItem value={"rense"}>Rense</MenuItem>
            <MenuItem value={"osamia"}>Osa Mia</MenuItem>
            <MenuItem value={"estebanguerrero"}>Esteban Guerrero</MenuItem>
            <MenuItem value={"developer"}>Developer</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth className={classes.formControl}>
          <TextField
            onChange={this.onEmailChange}
            label="email"
            className={classes.textField}
            value={this.state.email}
            margin="normal"
          />
        </FormControl>
        <FormControl fullWidth className={classes.formControl} />
      </div>
    );
  }

  renderCode() {
    const { classes } = this.props;
    if (this.props.user.view != "CODE") return null;

    return (
      <div>
        <TextField
          onChange={this.onCodeChange}
          label="code"
          className={classes.textField}
          value={this.state.code}
          margin="normal"
        />
      </div>
    );
  }

  renderButton() {
    const { classes } = this.props;
    if (this.props.user.view != "CODE" && this.props.user.view != "EMAIL") return null;

    return (
      <Button onClick={this.onFormClick} color="success" simple size="lg" block>
        Ingresar
      </Button>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.content}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={6} md={4}>
              <form>
                <Card login className={classes[(this.state.cardAnimaton, classes.cardLogin)]}>
                  <CardHeader className={`${classes.cardHeader} ${classes.textCenter}`} color="success">
                    <img className={classes.logo} src={Colibri} />
                  </CardHeader>
                  <CardBody>
                    {this.renderEmail()}
                    {this.renderCode()}
                    <p className={classes.errorMessage}>{this.state.error}</p>
                  </CardBody>
                  <CardFooter className={classes.justifyContentCenter}>{this.renderButton()}</CardFooter>
                </Card>
              </form>
            </GridItem>
          </GridContainer>
        </div>
        <div className={classes.fullPageBackground} style={{ backgroundImage: "url(" + bgImage + ")" }} />
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(style)(LoginPage);
