import React from 'react';

import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import pagesStyle from "assets/jss/material-dashboard-pro-react/layouts/pagesStyle.jsx";
import TextField from '@material-ui/core/TextField';


import Colibri from "assets/img/colibri.svg";
import bgImage from "assets/img/forest.jpg";

import * as Sentry from '@sentry/browser';


const style = {
  ...loginPageStyle, ...pagesStyle,
  cardLogin: {
    backgroundColor: "rgba(255,255,255,0.7)"
  }
}

class ErrorBoundary extends React.Component {

  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden"
    };
  }



  render() {
    const { classes } = this.props;
    console.log(this.props)

    return (

      <div className={classes.content}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={6} md={4}>
              <form>
                <Card login className={classes[this.state.cardAnimaton, classes.cardLogin]}>
                  <CardHeader
                    className={`${classes.cardHeader} ${classes.textCenter}`}
                    color="rose"
                  >
                    <img className={classes.logo} src={Colibri} />
                  </CardHeader>
                  <CardBody>
                    Ocurrio un error imprevisto y no se puede continuar
                  </CardBody>
                  <CardFooter className={classes.justifyContentCenter}>
                    <Button onClick={() => Sentry.showReportDialog({})} color="rose" simple size="lg" block>
                      Reportar Error
                    </Button>
                  </CardFooter>
                </Card>
              </form>
            </GridItem>
          </GridContainer>
        </div>
        <div
          className={classes.fullPageBackground}
          style={{ backgroundImage: "url(" + bgImage + ")" }}
        />

      </div >
    );
  }
}

export default withStyles(style)(ErrorBoundary);

