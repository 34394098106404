import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./reducers";
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import logger from 'redux-logger'

import thunk from 'redux-thunk';

const history = createBrowserHistory()

export default function () {
  return createStore(
    connectRouter(history)(rootReducer),
    compose(
      applyMiddleware(
        routerMiddleware(history),
        thunk,
        //  logger
      )
    )
  )
}
