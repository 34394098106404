import React from "react";
import PropTypes from 'prop-types';
import moment from "moment";

class LinkFormatter extends React.Component {

  render() {
    var value = this.props.value;

    if (!value || value.length == 0) return null;

    if (this.props.column.prefix) value = this.props.column.prefix + value;



    return (
      <a target="_blank" href={value}>{value}</a>
    )
  }
}

export default function (column, reload, getRow, handleUpdate) {
  return class extends React.Component {
    render() {

      return <LinkFormatter handleUpdate={handleUpdate} reload={reload} getRow={getRow} column={column} {...this.props} />
    }
  }
}