import { alert } from "redux/reducers/header";
import { onFieldChange, onFieldsChange, onArrayFieldChange } from "redux/reducers/form";

import moment from "moment"


const onPagoCxPFieldChange = (_this, formId, name, value, extra) => {

  var form = _this.props.form.item;

  var facturas = form.lineaPagoCxP;

  if (name == "lineaPagoCxP") {
    var monto = value.reduce((total, lineaPago) => total + lineaPago.monto, 0)
    _this.props.dispatch(onFieldChange(formId, "monto", monto));
    return _this.props.dispatch(onArrayFieldChange(formId, name, value));
  }
  _this.props.dispatch(onFieldChange(formId, name, value));
}


export default onPagoCxPFieldChange