import { alert } from "redux/reducers/header";
import { onFieldChange, onFieldsChange } from "redux/reducers/form";


const onProveedorFieldChange = (_this, formId, name, value, extra) => {

  var form = _this.props.form.item;

  if (name == "tipoCedula") {
    var newItem = _this.props.dispatch(onFieldChange(formId, name, value))
      .then((newItem) => {
        if (value == "Internacional") {
          _this.props.dispatch(onFieldChange(formId, "cedula", "No Aplica"))
          _this.props.dispatch(onFieldChange(formId, "moneda", "USD"))
        }
        else {
          _this.props.dispatch(onFieldChange(formId, "moneda", "CRC"))
          if (form.cedula == "No Aplica") _this.props.dispatch(onFieldChange(formId, "cedula", ""))
        }
      })
  }

  else _this.props.dispatch(onFieldChange(formId, name, value));

}
export default onProveedorFieldChange;