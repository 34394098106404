import React from "react";
import PropTypes from 'prop-types';
import moment from "moment";

class NumberFormatter extends React.Component {

  render() {
    return (
      moment(this.props.value).format("YYYY-MM-DD")
    )
  }
}

export default function (column, reload, getRow, handleUpdate) {
  return class extends React.Component {
    render() {

      return <NumberFormatter handleUpdate={handleUpdate} reload={reload} getRow={getRow} column={column} {...this.props} />
    }
  }
}