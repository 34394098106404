import "./App.css";
import React, { Suspense } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Route, Switch } from "react-router-dom";
import "assets/scss/material-dashboard-pro-react.css?v=1.2.0";
import RouteBase from "views/base/route";
import SideBar from "./components/Sidebar";
import Login from "components/Login";
import AppHome from "AppHome";

import { AppMap, getMenu } from "AppMap";

const drawerWidth = 240;

const styles = theme => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex"
  },
  appbar: {
    zIndex: theme.zIndex.drawer + 1
  },
  sidebar: {
    zIndex: theme.zIndex.drawer - 2
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36
  },
  hide: {
    display: "none"
  },

  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    width: "102vw",
    backgroundColor: theme.palette.background.default
  }
});

class App extends React.Component {
  state = {
    open: false
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes, theme } = this.props;

    return (
      <div className={classes.root}>
        <SideBar
          className={classes.sidebar}
          items={getMenu()}
          open={this.state.open}
          onClose={this.handleDrawerClose}
        />
        <main className={classes.content}>
          <Switch>
            <Route
              path="/:type"
              render={props => {
                return (
                  <RouteBase
                    handleDrawerOpen={this.handleDrawerOpen}
                    namespace={props.match.params.type}
                    menu={AppMap[props.match.params.type]}
                    operationMap={AppMap[props.match.params.type]}
                    {...props}
                  />
                );
              }}
            />
            <Route
              path="/"
              render={() => {
                return (
                  <AppHome
                    items={getMenu()}
                    showMenu={() => {
                      this.setState({ open: true });
                    }}
                  />
                );
              }}
            />
          </Switch>

          <Login />
        </main>
      </div>
    );
  }
}

const menuItems = [
  { id: "tablet", default: "orden", title: "Tablet", icon: "TabletMac" },
  { id: "venta", default: "orden", title: "Venta", icon: "LocationCity" },
  { id: "credito", default: "saldo", title: "Credito", icon: "AccountBalance" },
  { id: "inventario", default: "boleta", title: "Inventarios", icon: "MoveToInbox" },
  { id: "cxp", default: "facturaCxP", title: "Cuentas por Pagar", icon: "Payment" },
  { id: "contabilidad", default: "account", title: "Contabilidad", icon: "AttachMoney" },
  { id: "auditoria", default: "registro", title: "Auditoria", icon: "Visibility" },
  { id: "administracion", default: "usuario", title: "Administracion", icon: "Settings" }
];

export default withStyles(styles, { withTheme: true })(App);
