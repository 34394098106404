import red from "@material-ui/core/colors/red";

import orange from "@material-ui/core/colors/orange";

export default theme => ({
  root: {
    position: "absolute",
    right: 0,
    bottom: 0
  },
  fab: {
    position: "absolute",
    right: 20,
    bottom: 20,
    zIndex: 1000
  },

  listRoot: {
    width: "100%",
    minHeight: "60vh",
    maxHeight: "70vh",
    maxWidth: "30vw",
    minWidth: "20vw",
    overflow: "auto",
    backgroundColor: theme.palette.background.paper
  },
  inline: {
    display: "inline"
  },
  card: {
    maxWidth: 400,
    margin: 40
  },
  media: {
    height: 0,
    display: "none",
    paddingTop: "56.25%" // 16:9
  },
  actions: {
    display: "flex"
  },
  expand: {
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    }),
    marginLeft: "auto",
    [theme.breakpoints.up("sm")]: {
      marginRight: -8
    }
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  avatar: {
    backgroundColor: red[500]
  },
  contentRoot: {
    paddingTop: 0
  },
  chipOn: {
    backgroundColor: orange[500]
  },
  chipRed: {
    backgroundColor: red[800]
  }
});
