import React, { Fragment } from 'react';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

import Fab from '@material-ui/core/Fab';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DragIndicator from '@material-ui/icons/DragIndicator';
import TouchApp from '@material-ui/icons/TouchApp';

import Add from '@material-ui/icons/Add';
import SettingsBack from '@material-ui/icons/SettingsBackupRestore';
import Print from '@material-ui/icons/TableChart';

import PrintIcon from '@material-ui/icons/Print';
import Info from '@material-ui/icons/Info';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneOutline from '@material-ui/icons/DoneOutline';
import Description from '@material-ui/icons/Description';
import Paper from '@material-ui/core/Paper';
import MenuList from '@material-ui/core/MenuList';

import DoneAll from '@material-ui/icons/DoneAll';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';

export const styles = (theme) => {
  console.log(theme)
  return {

    root: {
      marginRight: 5,
      marginTop: 5
    },
    buttonRoot: {
      position: 'absolute',
      top: 147,
      left: 34,
      zIndex: 1000,


    },
    actions: {

      backgroundColor: "rgba(255,255,255,0.8)"
    },
    actionsClosed: {
      backgroundColor: "inherit"
    },
    button: {
      backgroundColor: theme.palette.secondary[600],
    },
    standardButton: {
      marginLeft: 7
    },
    icon: {
      color: "#fff",
      fill: "#fff"
    },
    popper: {
      zIndex: 1999
    },
    menuItem: {
      '&:focus': {
        backgroundColor: theme.palette.primary.main,
        '& $primary, & $icon': {
          color: theme.palette.common.white,
        },
      },
    },
    primary: {},
    icon: {},
  }

};

const ITEM_HEIGHT = 48;




class LongMenu extends React.Component {
  state = {
    anchorEl: null,
    anchorEl1: null,
    anchorEl3: null,
    anchorEla: null,
    items: [],
    open: false
  };


  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedItems.length > 0) this.setState({ open: true, items: nextProps.selectedItems });
    else this.setState({ open: false, items: nextProps.selectedItems });
  }

  handleClose = (name) => {
    return () => {
      this.setState({ [name]: null });
    }
  };

  handleVisibility = () => {
    this.setState(state => ({
      open: false,
      hidden: !state.hidden,
    }));
  };

  handleClick = () => {
    this.setState(state => ({
      open: !state.open,
    }));
  };

  handleOpen = () => {
    if (!this.state.hidden) {
      this.setState({
        open: true,
      });
    }
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleItemClick = (e) => {
    var action = JSON.parse(e.currentTarget.dataset.action);
    if (action.type == "modal") this.props.handleModalClick(action)
    else this.props.handleItemClick(action);
  }

  handleModalClick = (e) => {
    this.props.handleModalClick(e.currentTarget.dataset.id)
  }

  handlePrint = (e) => {
    this.props.onPrint();
  }
  handleGraph = (e) => {
    this.props.onGraph();
  }

  handleRefreshClick = (e) => {
    this.props.handleRefreshClick();
  }

  handleResetClick = (e) => {
    this.props.handleResetClick();
  }

  handleCreateClick = (e) => {
    this.props.handleItemClick({title: "Crear"})
  }

  handleBatchClick = (e) => {
    this.props.handleBatchClick();

  }

  handleMenuClick = name => {
    return (event) => {
      this.setState({ [name]: event.currentTarget });
    }
  };


  handleMenuClose = (name) => {
    return () => {
      this.setState({ [name]: null });
    }
  };

  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleClosea = event => {
    if (this.anchorEla.contains(event.target)) {
      return;
    }

    this.setState({ open: false });
  };

  renderMoreOptions() {
    const { anchorEla, open } = this.state;

    const { classes } = this.props;

    const iconActions = {
      "Aplicar": DoneOutline,
      "Aprobar": DoneAll,
      "Imprimir": PrintIcon,
      "Borrar": DeleteIcon,
      "Esperar": Info,
      "Proforma": Description,
      "Info": Info,
    }

    if (!this.props.actions) return null;

    var estados = [];
    this.state.items.forEach((item) => {
      if (item.estado) {
        var parsedEstado = item.estado.replace("por ", "").toLowerCase();
        if (estados.indexOf(parsedEstado) == - 1) estados.push(parsedEstado);
      }
    })

    var finalActions = this.props.actions.filter((action) => {
      if (action.title == "Crear" || action.title == "Editar") return false;

      if (estados.indexOf("aplicar") > -1 && action.title == "Aprobar") return false;
      if (estados.indexOf("aprobar") > -1 && action.title.title == "Aplicar") return false;
      if (estados.indexOf("imprimir") > -1 && (action == "Aprobar" || action.title == "Aplicar")) return false;

      if (estados.indexOf("archivado") > -1 && (["Aplicar", "Aprobar", "Borrar"].indexOf(action.title) > -1)) return false;
      if (estados.indexOf("por archivar") > -1 && (["Aplicar", "Aprobar", "Borrar"].indexOf(action.title) > -1)) return false;

      return true;
    })
    if (finalActions.length == 0 && (this.props.modals || []).length == 0) return null


    var Icon = TouchApp;
    if (this.state.items && this.state.items.length == 1) Icon = MoreVertIcon
    else if (this.state.items && this.state.items.length > 1) Icon = DragIndicator;

    return <Fragment>

      <Fab
        className={ classes.buttonRoot }
        buttonRef={node => {
          this.anchorEla = node;
        }}
        aria-owns={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={this.handleToggle}
        color="primary"
      >
        <Icon />
      </Fab>
      <Popper
        className={
          classes.popper
        }
        placement="right-start"
        open={open}
        anchorEl={this.anchorEla}
        transition>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            id="menu-list-grow"
            style={{ transformOrigin: placement === 'bottom' ? 'right top' : 'right top' }}
          >
            <Paper >
              <ClickAwayListener onClickAway={this.handleClose}>
                <MenuList>
                  {finalActions.map((action) => {
                    var ButtonIcon = iconActions[action.icon] || Info;

                    return <MenuItem
                      key={action.title}
                      onClick={this.handleItemClick}
                      data-action={JSON.stringify(action)}
                    >
                      <ListItemIcon className={classes.icon}>
                        <ButtonIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ primary: classes.primary }} inset primary={action.title} />
                    </MenuItem>
                  })
                  }
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  }



  render() {

    const { anchorEl, anchorEl1, anchorEl3 } = this.state;
    const { classes } = this.props;
    const Icon = this.props.icon || MoreVertIcon;
    let menuHeight = ITEM_HEIGHT * this.props.actions.length;
    if (menuHeight > ITEM_HEIGHT * 7) menuHeight = ITEM_HEIGHT * 6;


    return (
      <Grid item>
        <div className={classes.root}>
          <Button
            className={classes.standardButton}
            classes={classes}
            variant="fab"
            color="default"
            aria-label="More"
            aria-owns={anchorEl1 ? 'long-menu1' : null}
            aria-haspopup="true"
            onClick={this.handleMenuClick("anchorEl1")}

          >
            <SettingsBack />
          </Button>
          <Menu
            id="long-menu1"
            anchorEl={anchorEl1}
            open={Boolean(anchorEl1)}
            onClose={this.handleMenuClose("anchorEl1")}

            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 3,
                width: 200,
              },
            }}
          >

            <MenuItem onClick={this.handleRefreshClick}>
              Recargar
            </MenuItem>
            <MenuItem onClick={this.handleResetClick}>
              Reset Filtros
            </MenuItem>
            {this.props.batch ?
              <MenuItem onClick={this.handleBatchClick}>
                Batch
            </MenuItem>
              : null}

          </Menu>


          <Button classes={classes} variant="fab"
            className={classes.standardButton}
            color="default"
            aria-label="More"
            aria-owns={anchorEl3 ? 'long-menu3' : null}
            aria-haspopup="true"
            onClick={this.handleMenuClick("anchorEl3")}
          >
            <Print />
          </Button>
          <Menu
            id="long-menu3"
            anchorEl={anchorEl3}
            open={Boolean(anchorEl3)}
            onClose={this.handleMenuClose("anchorEl3")}

            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 3,
                width: 200,
              },
            }}
          >

            <MenuItem onClick={this.handlePrint}>
              Imprimir
            </MenuItem>
            <MenuItem onClick={this.handleGraph}>
              Analizar
            </MenuItem>

          </Menu>

          {this.props.actions.map((option) => {
            if (option.title == "Crear") {
              return <Button
                className={classes.standardButton}
                classes={classes}
                variant="fab"
                color="default"
                onClick={this.handleCreateClick}
              >
                <Add />
              </Button>
            }
            return null;
          })
          }

          {this.renderMoreOptions()}
        </div>
      </Grid>

    );
  }
}


export default withStyles(styles, { withTheme: true })(LongMenu);

