import React from "react";

import ReactDOM from 'react-dom';

import DataGrid from 'react-data-grid';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import FileUpload from "components/CustomUpload/fileUpload";
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import { connect } from "react-redux";
import { simpleApi } from "redux/reducers/api";

import ClienteFormatterView from "./clienteFormatterView";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";


const styles = theme => {

  return {

    link: {
      color: theme.palette.primary[900],
      textTransform: "capitalize"
    },

  }
}

const mapStateToProps = (store, props) => {
  return {
  }
}


class ClienteFormatter extends React.Component {

  state = {
    cliente: { saldos: [], loading: true },
    open: false,
  };


  onShowModal = () => {
    this.setState({ open: true });
    this.props.dispatch(simpleApi("cliente/detailsFromOrdenView", { id: this.props.row.clienteId }))
      .then((cliente) => {
        this.setState({ cliente: cliente });
      })
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleComplete = (fileKey) => {
    this.setState({ open: false });
    this.props.handleUpdate({
      cellKey: this.props.column.key,
      updated: { [this.props.column.key]: fileKey },
      fromRowId: this.props.row.id
    })

  }


  renderModal() {
    var row = this.props.row;
    return <div>
      <Dialog
        open={this.state.open}
        TransitionComponent={Transition}
        maxWidth={"md"}
        fullWidth
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {`${this.state.cliente.name} - limite: ${Number(this.state.cliente.creditoLimite).toLocaleString() || 0}`}
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Saldos
          </DialogContentText>

          <ClienteFormatterView keys={["consecutivo", "plazoActual", "total"]} cliente={this.state.cliente} />

        </DialogContent>
        <DialogActions>

          <Button onClick={this.handleClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  }

  renderIcon() {
    return this.props.value;
  }

  render() {
    const { classes } = this.props;

    return (
      <div  >
        {this.renderModal()}

        <a className={classes.link} onClick={this.onShowModal}>{this.props.value}</a>

      </div>
    )
  }
}


const ClienteFormatterStyle = withStyles(styles, { withTheme: true })(connect(mapStateToProps)(ClienteFormatter));

export default function (column, reload, getRow, handleUpdate) {
  return class extends React.Component {
    render() {

      return <ClienteFormatterStyle handleUpdate={handleUpdate} reload={reload} getRow={getRow} column={column} {...this.props} />
    }
  }
}
function Transition(props) {
  return <Slide direction="up" {...props} />;
}



