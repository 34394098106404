import React from "react";
import BaseElement from "./base";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "@material-ui/core/Button"
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardAction from "components/Card/CardAction.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import InputLabel from "@material-ui/core/InputLabel";
import CustomInput from "components/CustomInput/CustomInput";
import withStyles from "@material-ui/core/styles/withStyles";

// material-ui icons
import Person from "@material-ui/icons/Person";
import Edit from "@material-ui/icons/Edit";
import Add from "@material-ui/icons/Add";

import Close from "@material-ui/icons/Close";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
// core components
import Table from "components/Table/Table.jsx";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import { onLoadMetaData, selectMetadataByType } from "redux/reducers/metadata";

import { connect } from "react-redux";

import Integer from "./integer";
import String from "./string";
import Number from "./number";
import Lookup from "./lookup";
import Select from "./select";
import Date from "./date";
import Autocomplete from "./autocomplete";


const typeMap = {
  "integer": Integer,
  "string": String,
  "number": Number,
  "lookup": Lookup,
  "select": Select,
  "autocomplete": Autocomplete,
  "date": Date,
}

const styles = theme => ({
  ...extendedTablesStyle
})

const mapStateToProps = (store, props) => {
  return {
    metadata: selectMetadataByType(store, props.column.key)
  }
}



class TableElement extends BaseElement {

  constructor() {
    super();
    this.state = { columns: [] };
  }

  componentWillMount() {

    //Comes from link, load metadata
    if (!this.props.metadata) this.props.dispatch(onLoadMetaData(this.props.column.key));

  }

  componentWillReceiveProps(nextProps) {
    var metadata = nextProps.metadata;

    if (!metadata || !metadata.properties) return;


    var keys = this.props.column.fields;

    var columns = keys.filter((key) => {
      if (key == "id") return false;
      return true;
    })
      .map(function (key) {
        if (key.indexOf("__") == 0) key = key.replace("__", "");
        return Object.assign(metadata.properties[key], { name: metadata.properties[key].title, key: key }, {})
      })

    this.setState({ columns: columns })
  }

  onFieldChange = (e) => {
    if (e.persist) e.persist();

    var value = e.value || e.currentTarget.value;
    var name = e.name || e.target.name;
    //if(value == "") value = null;
    this.props.onChange(name, this.transformValue(value));
  }



  getValue() {
    let column = this.props.column;
    return this.props.item[column.key] || [];
  }

  onRemove = (e) => {

    var newValues = this.getValue().filter((item) => {
      var idField = this.props.column.idField;
      if (e.currentTarget.dataset.id == item[idField]) return false;
      return true;
    })

    this.props.onChange(this.props.column.key, newValues);
  }

  onAddItem = () => {
    this.props.onShowModal("AddItem")
  }

  onInnerFieldChange = (name, value, id) => {

    var newValues = this.getValue().map((item) => {
      var idField = this.props.column.idField;
      if (parseInt(id) == item[idField]) {
        item[name] = value;
        return item;
      }
      return item
    })
    this.props.onChange(this.props.column.key, newValues);
  }

  renderEditableField(column, item, index) {

    var BaseElement = typeMap[column.element || column.subType || column.type];
    if (!BaseElement) BaseElement = typeMap["string"];
    const elementId = Math.random();
    var fieldStatus = this.props.fieldStatus[this.props.column.key] ? this.props.fieldStatus[this.props.column.key][index] || {} : {};

    return <BaseElement
      id={item[this.props.column.idField]}
      key={column.key}
      dispatch={this.props.dispatch}
      dispatchSimpleApi={this.dispatchSimpleApi}
      elementId={column.key}
      size={12}
      onChange={this.onInnerFieldChange}
      item={item}
      column={column}
      fieldStatus={fieldStatus}
      showCreateModal={this.props.showCreateModal}
    />
  }

  getData() {
    let column = this.props.column;
    let value = this.getValue();
    if (value.length == 0) return [];

    const { classes } = this.props;

    const buttons = [{ color: "danger", icon: Close }].map((prop, key) => {
      return (
        <Button variant="fab" mini onClick={this.onRemove} color={"primary"} customClass={classes.actionButton} key={key}>
          <prop.icon className={classes.icon} />
        </Button>
      );
    });

    var data = value.map((item, index) => {
      var keys = this.props.column.fields;
      var keyArray = keys.map((key) => {
        var column = this.props.metadata.properties[key]
        if (this.props.column.editables.indexOf(key) > -1) return this.renderEditableField(column, item, index)
        else return item[key]
      })

      keyArray.push(
        <Button mini data-id={item[this.props.column.idField]} onClick={this.onRemove} color={"danger"} customClass={classes.actionButton}>
          <Close className={classes.icon} />
        </Button>
      );
      return keyArray;
    })

    return data;
  }

  render() {
    if (!this.props.metadata) return null;

    let column = this.props.column;
    let value = this.getValue();
    if (typeof value == "undefined") value = "";
    var size = this.props.size;
    if (column.ui.fullWidth) size = 12;

    const { classes } = this.props;

    return <GridItem key={column.key} xs={12}>

      <div style={{ position: "relative" }}>

        <Button variant="fab"
          mini
          color="primary"
          onClick={this.onAddItem}
          style={{
            position: "absolute",
            top: "-70px",
            left: "95%",
            zIndex: 1000
          }}>
          <Add className={classes.icon} />
        </Button>

        <Table
          tableHead={this.state.columns.map((column) => column.title).concat(["Actions"])}
          tableData={this.getData()}
          customCellClasses={[
            classes.center,
            classes.right,
            classes.right
          ]}
          // 0 is for classes.center, 4 is for classes.right, 5 is for classes.right
          customClassesForCells={[0, 4, 5]}
          customHeadCellClasses={[
            classes.center,
            classes.right,
            classes.right
          ]}
          // 0 is for classes.center, 4 is for classes.right, 5 is for classes.right
          customHeadClassesForCells={[0, 4, 5]}
        />
      </div>
    </GridItem>
  }
}

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps)(TableElement));