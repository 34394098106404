import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";

import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";

import { connect } from "react-redux";

import Page from "./page";

import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import { onLogin, onCode, onSelectApi } from "redux/reducers/user";

const styles = theme => {
  return {
    appBar: {
      position: "relative"
    },
    flex: {
      flex: 1
    },
    formControl: {
      marginBottom: theme.spacing.unit * 1
    },
    root: {
      padding: theme.spacing.unit * 3
    },
    textField: {
      width: "100%"
    }
  };
};

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const mapStateToProps = (store, props) => {
  return {
    user: store.user
  };
};

class FullScreenDialog extends React.Component {
  state = {
    email: window.localStorage.getItem("email") || "",
    code: "",
    error: null
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  onLogin = () => {
    this.props.dispatch(onLogin(this.state.email)).catch(() => {
      this.setState({ error: "No se encontro el email." });
    });
  };

  onCode = () => {
    this.props.dispatch(onCode(this.state.code)).catch(() => {
      this.setState({ error: "El codigo es incorrecto." });
    });
  };

  onApiChange = event => {
    this.props.dispatch(onSelectApi(event.target.value));
  };

  onEmailChange = e => {
    this.setState({ email: e.currentTarget.value });
  };

  onCodeChange = e => {
    this.setState({ code: e.currentTarget.value });
  };

  renderEmail() {
    const { classes } = this.props;

    if (this.props.user.view != "EMAIL") return null;
    return (
      <div className={classes.root}>
        <FormControl fullWidth className={classes.formControl}>
          <InputLabel htmlFor="age-simple">Cuenta</InputLabel>
          <Select
            value={this.props.user.account}
            onChange={this.onApiChange}
            inputProps={{
              name: "api",
              id: "api-simple"
            }}>
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={"rodco"}>Rodco</MenuItem>
            <MenuItem value={"positiveion"}>Positive Ion</MenuItem>
            <MenuItem value={"mardaro"}>Mardaro</MenuItem>
            <MenuItem value={"rense"}>Rense</MenuItem>
            <MenuItem value={"osamia"}>Osa Mia</MenuItem>
            <MenuItem value={"estebanguerrero"}>Esteban Guerrero</MenuItem>
            <MenuItem value={"developer"}>Developer</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth className={classes.formControl}>
          <TextField
            onChange={this.onEmailChange}
            label="email"
            className={classes.textField}
            value={this.state.email}
            margin="normal"
          />
        </FormControl>
        <FormControl fullWidth className={classes.formControl}>
          <p>{this.state.error}</p>

          <Button onClick={this.onLogin} variant="fab" color="primary">
            <AddIcon />
          </Button>
        </FormControl>
      </div>
    );
  }

  renderCode() {
    const { classes } = this.props;

    if (this.props.user.view != "CODE") return null;

    return (
      <div>
        <TextField
          onChange={this.onCodeChange}
          label="code"
          className={classes.textField}
          value={this.state.code}
          margin="normal"
        />

        <Button onClick={this.onCode} variant="fab" color="primary">
          <AddIcon />
        </Button>
      </div>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Dialog fullScreen open={this.props.user.view != ""} TransitionComponent={Transition}>
          <Page {...this.props} />
        </Dialog>
      </div>
    );
  }
}

FullScreenDialog.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps)(FullScreenDialog));
