import React from "react";
import PropTypes from 'prop-types';
import moment from "moment";

class LinkFormatter extends React.Component {

  onClick = () => {

    var url = "/" + this.props.tableProps.metadata.namespace + "/" + this.props.column.metadataType + "/edit/" + this.props.row.id;
    this.props.tableProps.history.push(url);
  }

  render() {
    var value = this.props.value;

    if (!value || value.length == 0) value = "-";

    var hasEdit = false;

    if (!this.props.tableProps.metadata.actions) return (value);
    else {
      this.props.tableProps.metadata.actions.map((action) => {
        if (action.title == "Editar") hasEdit = true;
      })
    }

    if (!hasEdit) return (value);

    return (
      <a onClick={this.onClick} >{value}</a>
    )
  }
}

export default function (column, reload, getRow, handleUpdate, tableProps) {
  return class extends React.Component {
    render() {
      return <LinkFormatter {...this.props} handleUpdate={handleUpdate} tableProps={tableProps} reload={reload} getRow={getRow} column={column} />
    }
  }
}