import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import classnames from "classnames";

import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/icons/NotificationsNone";
import { connect } from "react-redux";
import Divider from "@material-ui/core/Divider";
import ListSubheader from "@material-ui/core/ListSubheader";

import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import moment from "moment";
import Fab from "@material-ui/core/Fab";
import NotificationsActive from "@material-ui/icons/NotificationsActive";

import styles from "./style";

import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import RestoreIcon from "@material-ui/icons/Restore";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Chip from "@material-ui/core/Chip";

import Paper from "@material-ui/core/Paper";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import AppBar from "@material-ui/core/AppBar";
import SwipeableViews from "react-swipeable-views";

import { selectSocial, selectOptions, setOptions } from "redux/reducers/feed";
import { onLoadDocumentos, selectDocuments } from "redux/reducers/electronico";
import { subscribeToStandardChannel } from "redux/reducers/socket";

const mapStateToProps = (state, ownProps) => {
  return {
    feeds: selectSocial(state),
    documents: selectDocuments(state),
    options: selectOptions(state)
  };
};

class Feed extends React.Component {
  componentWillReceiveProps(nextProps) {
    if (nextProps.feeds.length != this.props.feeds.length) {
      this.props.dispatch(setOptions({ hasNew: true }));
    }
  }

  handleExpandClick = () => {
    this.props.dispatch(setOptions({ expanded: this.props.options.expanded }));
  };

  handleClick = event => {
    const { currentTarget } = event;

    this.props.dispatch(
      setOptions({
        hasNew: false,
        open: !this.props.options.open
      })
    );

    this.props.dispatch(onLoadDocumentos());
  };

  handleTabChange = (event, tabIndex) => {
    this.props.dispatch(setOptions({ tabIndex }));
  };

  handleTabChangeIndex = index => {
    this.props.dispatch(setOptions({ tabIndex: index }));
  };

  renderSocialList() {
    const { classes } = this.props;
    return (
      <List className={classes.listRoot}>
        {this.props.feeds.length == 0 ? (
          <ListItem alignItems="flex-start">
            <ListItemText primary={"No hay notificaciones"} />
          </ListItem>
        ) : (
          this.props.feeds.map(feed => {
            return (
              <Fragment key={feed.id}>
                <Divider />

                <ListItem alignItems="flex-start">
                  <ListItemIcon>
                    <Icon />
                  </ListItemIcon>
                  <ListItemText
                    primary={feed.source || "Sistema"}
                    secondary={
                      <React.Fragment>
                        <Typography component="span" className={classes.inline} color="textPrimary">
                          {feed.createdBy || ""}
                        </Typography>
                        {` - ${feed.message}`}
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </Fragment>
            );
          })
        )}
      </List>
    );
  }

  renderDocumentoList() {
    const { classes } = this.props;

    return (
      <List className={classes.listRoot}>
        {this.props.documents.length == 0 ? (
          <ListItem alignItems="flex-start">
            <ListItemText primary={"No hay documentos"} />
          </ListItem>
        ) : (
          this.props.documents.map(document => {
            var chipClass = classes.chipOn;
            if (document.estado == "por archivar") chipClass = classes.chipOff;
            else if (document.estado == "por revisar") chipClass = classes.chipRed;

            return (
              <Fragment key={document.clave}>
                <Divider />

                <ListItem alignItems="flex-start">
                  <ListItemIcon>
                    <Chip color="secondary" label={document.estado} className={chipClass} />
                  </ListItemIcon>
                  <ListItemText
                    primary={`${document.tipo} ${document.clave.fromClave()}`}
                    secondary={
                      <React.Fragment>
                        <Typography component="span" className={classes.inline} color="textPrimary">
                          {document.createdBy || ""}
                        </Typography>
                        {document.cliente}
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </Fragment>
            );
          })
        )}
      </List>
    );
  }

  renderNotificationContainer() {
    const { open } = this.props.options;
    const { classes } = this.props;

    if (!open) return null;

    return (
      <Paper className={classes.card}>
        <AppBar position="static" color="default">
          <Tabs
            value={this.props.options.tabIndex}
            onChange={this.handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            fullWidth>
            <Tab label="Electrónico" />
            <Tab label="Social" />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={"x-reverse"}
          index={this.props.options.tabIndex}
          onChangeIndex={this.handleTabChangeIndex}>
          <TabContainer dir={"x-reverse"}>{this.renderDocumentoList()}</TabContainer>
          <TabContainer dir={"x-reverse"}>{this.renderSocialList()}</TabContainer>
        </SwipeableViews>
      </Paper>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        {this.renderNotificationContainer()}

        <Fab
          onClick={this.handleClick}
          className={classes.fab}
          color={this.props.options.hasNew ? "primary" : "secondary"}
          aria-label="Add">
          <NotificationsActive />
        </Fab>
      </div>
    );
  }
}

function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

const ConnectedFeed = connect(mapStateToProps)(Feed);

export default withStyles(styles, { withTheme: true })(ConnectedFeed);
