import Ajax from "../helpers/ajax";
import update from 'immutability-helper';
import moment from 'moment';

var init = {
  serverErrorsById: {},
  showProgressBar: false,
  alert: null,
  alertQueue: []
};

export function dismissAlert() {
  return {
    type: "HEADER_ALERT_DISMISS"
  }
}

export function alert(message, type, autoHide) {
  return function (dispatch, getState) {
    var words = (message || "").split(" ").length;
    var readingTimeInMiliSeconds = (words / 2) * 1000;

    const showMessage = (message, type, autoHide) => {
      dispatch({ type: "HEADER_ALERT", payload: { message: message, type: type, timestamp: moment(), readingTime: readingTimeInMiliSeconds } });

      if (autoHide) setTimeout(() => {
        dispatch({ type: "HEADER_ALERT_POP", payload: { message: message, type: type } });
      }, readingTimeInMiliSeconds)
    }

    if (getState().header.alert) {

      dispatch({ type: "HEADER_ALERT_POP" });

      setTimeout(() => {
        showMessage(message, type, autoHide);
      }, 500)
    } else showMessage(message, type, autoHide);

  }
}


export function dismissServerError(id) {
  return { type: "DISMISS_SERVER_ERROR", payload: { id: id } }
}

export default (state = init, action) => {

  switch (action.type) {

    case "HEADER_ALERT_DISMISS": {
      return { ...state, alert: null }
    }

    case "HEADER_ALERT": {
      {
        var nState = {
          ...state,
          alert: action.payload

        };
        return nState;
        break;
      }
    }

    case "HEADER_ALERT_POP": {
      {

        var nState = {
          ...state,
          alert: null

        };
        return nState;
        break;
      }
    }

    case "TOAST_DISMISS_NOTIFICATION":
      {
        return {
          ...state,
          count: state.count - 1,
          notifications: state.notifications.filter(item => item.id !== action.notification.id)
        }
        break;
      }

    case "AJAX_HTTP_ERROR":
      {
        return {
          ...state,
          count: state.count - 1,
          //notifications: state.notifications.concat(parseError(action.error, "")),
          loading: 0
        }
        break;
      }
    case "AJAX_API_ERROR":
      {
        return {
          ...state,
          count: state.count - 1,
          //notifications: state.notifications.concat(parseError(action.error, "")),
          loading: 0
        }
        break;
      }
    case "AJAX_SERVER_ERROR":
      {
        return {
          ...state,
          count: state.count - 1,
          //notifications: state.notifications.concat(parseError(action.error, "")),
          loading: 0
        }
        break;
      }
    case "AJAX_HTTP_ERROR_IN_LOGIN":
      {
        return {
          ...state,
          count: state.count - 1,
          //notifications: state.notifications.concat(parseError(action.error, "")),
          loading: 0
        }
        break;
      }
    case "AJAX_START":
      {
        return {
          ...state,
          showProgressBar: true
        }
        break;
      }

    case "AJAX_COMPLETE":
      {
        return {
          ...state,
          showProgressBar: false
        }
        break;
      }



    case "AJAX_500":
      {
        return {
          ...state,
          serverErrorsById: { ...state.serverErrorsById, [action.payload.id]: action.payload.error }
        }
        break;
      }

    case "DISMISS_SERVER_ERROR":
      {
        let serverErrorsById = Object.assign({}, state.serverErrorsById)
        delete serverErrorsById[action.payload.id];
        return { ...state, serverErrorsById: serverErrorsById }
      }

    case "AJAX_IDLE":
      {
        return {
          ...state,
          loading: 0
        }
        break;
      }
  }
  return state;

}

export function selectAllServerErrors(state) {
  return Object.keys(state.header.serverErrorsById).map(function (key) {
    var error = state.header.serverErrorsById[key];
    error.id = key;
    return error;
  })
}

