import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListSubheader from '@material-ui/core/ListSubheader';



import { Link } from "react-router-dom";

import TabletMac from '@material-ui/icons/TabletMac';
import LocationCity from '@material-ui/icons/LocationCity';
import AccountBalance from '@material-ui/icons/AccountBalance';
import MoveToInbox from '@material-ui/icons/MoveToInbox';
import Payment from '@material-ui/icons/Payment';
import Exit from '@material-ui/icons/ExitToApp';
import Visibility from '@material-ui/icons/Visibility';



import AttachMoney from '@material-ui/icons/AttachMoney';
import Settings from '@material-ui/icons/Settings';
import CardHeader from "components/Card/CardHeader.jsx";
import Colibri from "assets/img/colibri.svg";



import { connect } from "react-redux";
import { onLogout } from "redux/reducers/user";


import image from "assets/img/bg-6.jpg";



// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import pagesStyle from "assets/jss/material-dashboard-pro-react/layouts/pagesStyle.jsx";
import TextField from '@material-ui/core/TextField';
import {
  container,
  cardTitle
} from "assets/jss/material-dashboard-pro-react.jsx";



import bgImage from "assets/img/forest.jpg";

import * as Sentry from '@sentry/browser';
import Menu from "components/Sidebar/menu";



const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);



const iconMap = {
  TabletMac: TabletMac,
  LocationCity: LocationCity,
  AccountBalance: AccountBalance,
  MoveToInbox: MoveToInbox,
  Payment: Payment,
  AttachMoney: AttachMoney,
  Settings: Settings,
  Visibility: Visibility
}


const styles = theme => ({

  list: {
    width: 250,
    opacity: ".53",
    color: "#fff",
    height: "100%",
    opacity: ".93",
    zIndex: "1",
    height: "100%",
    width: "100%",
    display: "block",

    backgroundSize: "cover",
    backgroundPosition: "center center",
    transition: "all 300ms linear",
  },


  cardLogin: {
    background: "rgba(0,0,0,0.4)"
  },

  container,
  cardTitle: {
    ...cardTitle,
    color: "#FFFFFF"
  },
  textCenter: {
    textAlign: "center"
  },
  content: {
    paddingTop: 20,
    minHeight: "calc(100vh - 80px)",
    position: "relative",
    zIndex: "4",
    backgroundColor: "rgba(33,33,33,0.7)"
  },
  justifyContentCenter: {
    justifyContent: "center !important"
  },
  customButtonClass: {
    "&,&:focus,&:hover": {
      color: "#FFFFFF"
    },
    marginLeft: "5px",
    marginRight: "5px"
  },
  inputAdornment: {
    marginRight: "18px"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  cardHidden: {
    opacity: "0",
    transform: "translate3d(0, -60px, 0)"
  },
  cardHeader: {
    marginBottom: "20px"
  },
  socialLine: {
    padding: "0.9375rem 0"
  },
  wrapper: {
    height: "auto",
    minHeight: "100vh",
    position: "relative",
    top: "0",
    overflow: "hidden"
  },
  fullPage: {
    "&:before": {
      backgroundColor: "rgba(0, 0, 0, 0.65)"
    },
    "&:before,&:after": {
      display: "block",
      content: '""',
      position: "absolute",
      width: "100%",
      height: "100%",
      top: "0",
      left: "0",
      zIndex: "2"
    }
  },
  fullPageBackground: {
    position: "absolute",
    zIndex: "-1",
    height: "100%",
    width: "100%",
    display: "block",
    top: "0",
    left: "0",
    backgroundSize: "cover",
    backgroundPosition: "center center"
  }


})

const mapStateToProps = (store, props) => {
  return {
    user: store.user.user
  }
}

class TemporaryDrawer extends React.Component {

  state = {
    cardAnimaton: "cardHidden"
  };

  menuList() {
    return [
    ]
  }

  onLogout = () => {
    this.dispatch(onLogout());
  }


  render() {
    const { classes } = this.props;


    return (

      <div className={classes.content}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={6} md={4}>
              <form>
                <Card login className={classes.cardLogin}>
                  <CardHeader
                    className={`${classes.cardHeader} ${classes.textCenter}`}
                    color="success"
                  >
                    <h4>eFactura</h4>
                    <img className={classes.logo} src={Colibri} />
                  </CardHeader>
                  <CardBody>
                    
                  <Menu  onClose={this.props.onClose} items={this.props.items} />

                  
                  </CardBody>
                  <CardFooter className={classes.justifyContentCenter}>

                  </CardFooter>
                </Card>
              </form>
            </GridItem>
          </GridContainer>
        </div>
        <div
          className={classes.fullPageBackground}
          style={{ backgroundImage: "url(" + bgImage + ")" }}
        />

      </div >


    );
  }
}

TemporaryDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps)(TemporaryDrawer));


