import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

// core components
import Button from "components/CustomButtons/Button.jsx";

import defaultImage from "assets/img/image_placeholder.jpg";
import defaultAvatar from "assets/img/placeholder.jpg";
import CircularProgress from '@material-ui/core/CircularProgress';
import { simpleApi, selectApiResponseByFormId } from "redux/reducers/api";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


import { connect } from "react-redux";

const mapStateToProps = (store, props) => {
  return {
  }
}


class FileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage,
      loading: false
    };
  }

  handleClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.refs.fileInput.click();
  }


  upload = (response, file) => {
    const { onComplete, onClose } = this.props;

    const xhr = new XMLHttpRequest();
    xhr.open('PUT', response.signedRequest);
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) onComplete(response.key);
        else onClose();
      }
    };
    xhr.send(file);
  }

  handleImageChange = (e) => {
    const { onComplete, onClose } = this.props;

    e.stopPropagation();
    e.preventDefault();
    this.setState({ loading: true })
    var _this = this;

    let reader = new FileReader();
    let file = e.target.files[0];

    this.props.dispatch(simpleApi(this.props.route, { key: file.name, isStaging: this.props.fileKey.indexOf("staging") > -1, cedula: this.props.item.cedula }))
      .then((res) => {
        _this.upload(res, file);
      })
      .catch((e) => {
        alert("Error cargando archivo");
        console.log(e);
        onClose();
      })

  }



  render() {
    var {
      avatar,
      addButtonProps,
      changeButtonProps,
      removeButtonProps
    } = this.props;
    return (
      <div>
        <DialogTitle id="form-dialog-title">Importar Factura</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Escoja un archivo XML de su computadora, luego tendra la oportunidad de revisarlo.
            </DialogContentText>
          <div className="fileinput text-center">
            <input
              accept="application/p12"
              type="file"
              onClick={(e) => e.stopPropagation()}
              onChange={this.handleImageChange}
              ref="fileInput"
            />

            {this.state.loading ? <CircularProgress size={50} /> : null}
            <div>
              {this.state.file === null ? (
                <Button {...addButtonProps} onClick={this.handleClick}>
                  {avatar ? "Add Photo" : this.props.label || ""}
                </Button>
              ) : (
                  <span>

                  </span>
                )}
            </div>
          </div>
        </DialogContent>
      </div>
    );
  }
}

FileUpload.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object
};

export default connect(mapStateToProps)(FileUpload);
