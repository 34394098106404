import { alert } from "redux/reducers/header";
import { onFieldChange, onFieldsChange } from "redux/reducers/form";


const onBoletaFieldChange = (_this, formId, name, value, extra) => {

  var form = _this.props.form.item;
  if (name == "tipo" && _this.props.form.item.tipo) return _this.props.dispatch(alert("no se puede cambiar de tipo"));

  return _this.props.dispatch(onFieldChange(formId, name, value));
}

export default onBoletaFieldChange;