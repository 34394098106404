import React from "react";
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Checkbox from "@material-ui/core/Checkbox";

// material-ui icons
import Assignment from "@material-ui/icons/Assignment";
import PanTool from "@material-ui/icons/PanTool";
import Edit from "@material-ui/icons/Edit";
import Check from "@material-ui/icons/Check";
import CheckCircle from "@material-ui/icons/CheckCircle";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Add from "@material-ui/icons/Add";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import AppBar from '@material-ui/core/AppBar';

import CoreButton from "@material-ui/core/Button";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';

import Hidden from "@material-ui/core/Hidden";

import { connect } from "react-redux";
import { throttle, debounce } from "throttle-debounce";
import { Switch, Route, Redirect } from "react-router-dom";

// material-ui components
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { onUpdateItem, selectFormById } from "redux/reducers/form";

import { onLoadDataById, selectDataByTypeAndId } from "redux/reducers/data";

import { onLoadMetaData, selectMetadataByType } from "redux/reducers/metadata";

import { simpleApi, selectApiResponseByFormId } from "redux/reducers/api";

import CircularProgress from '@material-ui/core/CircularProgress';
import CustomInput from "components/CustomInput/CustomInput";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import CardText from "components/Card/CardText.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Avatar from '@material-ui/core/Avatar';
import blue from '@material-ui/core/colors/blue';

import Tooltip from '@material-ui/core/Tooltip';

import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';


import Integer from "components/SmartForm/elements/integer";
import String from "components/SmartForm/elements/string";
import Number from "components/SmartForm/elements/number";
import Lookup from "components/SmartForm/elements/lookup";
import Multiselect from "components/SmartForm/elements/multiselect";
import Select from "components/SmartForm/elements/select";
import Table from "components/SmartForm/elements/table";
import Boolean from "components/SmartForm/elements/boolean";
import JsonList from "components/SmartForm/elements/jsonList";
import BillingSelector from "components/SmartForm/elements/billingSelector";
import Date from "components/SmartForm/elements/date";
import Autocomplete from "components/SmartForm/elements/autocomplete";

import {
  tooltip
} from "assets/jss/material-dashboard-pro-react.jsx";

const typeMap = {
  "integer": Integer,
  "string": String,
  "number": Number,
  "lookup": Lookup,
  "select": Select,
  "billingSelector": BillingSelector,
  "autocomplete": Autocomplete,
  "date": Date,
  "table": Table,
  "boolean": Boolean,
  "multiselect": Multiselect,
  "jsonList": JsonList
}

const styles = theme => {
  console.log(theme);

  return {
    appBar: {
      position: 'relative',
    },
    ...tooltip,
    cardIconTitle: {
      ...cardTitle,
      fontWeight: "300",
      marginTop: "6px",
      textTransform: "capitalize"
    },
    selectFormControl: {
      marginTop: 6
    },
    gridListRoot: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      width: "100%"
    },

    gridCard: {
      marginBottom: 20,
      marginTop: 10
    },
    subheader: {
      width: '100%',
    },

    pageHeader: {
      marginTop: "20px !important",
      marginBottom: "30px !important"
    },
    cardTitle: {
      marginTop: "0",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textTransform: "capitalize",
      textDecoration: "none",
      color: "#fff",
    },
    button: {
      marginRight: "15px"
    },
    avatarRoot: {
      backgroundColor: theme.palette.primary.dark,
      width: "auto",
      paddingLeft: 10,
      paddingRight: 10,
      height: 50,
      borderRadius: 2
    },
    cardHeaderRoot: {
      paddingBottom: 0,
      marginBottom: 0

    }
  }
}

const mapStateToProps = (store, props) => {
  return {
  }
}



class SmartForm extends React.Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
  }

  dispatchSimpleApi = (column, url, body) => {
    return this.props.dispatch(simpleApi(url, body));
  }

  dispatchShowModal = (elementId, key, url, body) => {
  }

  onKeyPress = (e) => {
  }

  onFieldChange = (name, value, extra) => {
    this.props.onFieldChange(this.props.formId, name, value, extra);
  }

  onSave = (e) => {
    this.props.onSave(this.props.form.item);
  }

  renderBackIcon() {
    var icon = <ArrowBack />
    if (this.props.modal) icon = <CloseIcon />
    return <Button onClick={this.props.onGoBack} justIcon round >
      {icon}
    </Button>
  }

  renderSaveIcon() {
    return <Tooltip
      id="tooltip-top"
      title="Guardar"
      placement="top"
      classes={{ tooltip: this.props.classes.tooltip }}
    >
      <CoreButton onClick={this.onSave} justIcon size="lg" round color="success">
        <Check />
      </CoreButton>
    </Tooltip>
  }

  renderPending() {
    if (this.props.form.loadStatus == "PENDING") return (<div style={{ margin: 20 }}>
      <CircularProgress />
    </div>);
  }

  renderError() {
    if (this.props.form.error && this.props.form.error.errorType == "AJAX_API_ERROR") {
      return <SnackbarContent
        message={this.props.form.error.error.label}
        close
        place="tr"
        color="danger"
      />
    }
  }

  renderInputs() {
    let { classes } = this.props;
    if (this.props.metadata.layout) {
      return <GridContainer  >
        {this.renderCards()}
      </GridContainer>
    }
  }

  renderCards() {
    let { classes } = this.props;

    return <div className={classes.gridListRoot}>

      <GridContainer className={classes.gridList} >
        {this.props.metadata.layout.map((layoutItem) => {
          if (layoutItem.list && !this.props.form.item.id) return null;

          return <GridItem key={layoutItem.title} xs={12} md={layoutItem.cols}>
            <Card className={classes.gridCard}>

              <CardHeader
                classes={{ root: classes.cardHeaderRoot }}
                avatar={
                  <Avatar classes={{ root: classes.avatarRoot }} aria-label="Recipe" className={classes.avatar}>
                    {layoutItem.title}
                  </Avatar>
                }
              />

              <CardContent>
                <GridContainer>
                  {this.renderFormContents(layoutItem)}
                </GridContainer>
              </CardContent>
            </Card>
          </GridItem>
        })
        }
      </GridContainer>
    </div>
  }

  renderFormContents(layoutItem) {

    function getSize(cols) {
      if (cols < 8) return 12;
      if (cols == 8) return 6;
      if (cols == 12) return 4;
    }

    return layoutItem.fields.map((key) => {

      var column = this.props.metadata.propertiesByKey[key];
      return this.renderInput(column, getSize(layoutItem.cols), key);
    })

  }

  renderInput = (column, size = 4, key) => {
    if (!column) {
      console.error("No se encontro una columna " + key + ", revise el JSON SCHEMA");
      return null;
    }

    var BaseElement = typeMap[column.element || column.type];
    if (this.props.typeMap && this.props.typeMap[column.key]) BaseElement = this.props.typeMap[column.key]

    if (!BaseElement) BaseElement = typeMap["string"];
    const elementId = Math.random();
    return <BaseElement
      key={column.key}
      formId={this.props.formId}
      metadata={this.props.metadata}
      metadataType={this.props.metadataType}
      dispatch={this.props.dispatch}
      dispatchSimpleApi={this.dispatchSimpleApi}
      elementId={column.key}
      size={size}
      onChange={this.onFieldChange}
      item={this.props.form.item}
      column={column}
      fieldStatus={this.props.form.fieldStatus || {}}
      onShowModal={this.props.onShowModal}
      onFastCreate={this.props.onFastCreate}
    />
  };

  render() {
    const { classes } = this.props;
    return (
      <div>

        {this.renderError()}
        <div style={{ width: "calc(100% - 15px)" }}>
          <GridContainer>
            {this.renderInputs()}
            {this.renderPending()}
          </GridContainer>

        </div>

      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps)(SmartForm));