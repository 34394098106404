import { alert } from "redux/reducers/header";
import { onFieldChange, onFieldsChange } from "redux/reducers/form";


const onFieldChangeAccount = (_this, formId, name, value, extra) => {
  var form = _this.props.form.item;

  if (name == "normalCredit" && value == true) _this.props.dispatch(onFieldsChange(formId, { "normalDebit": false }));
  if (name == "normalDebit" && value == true) _this.props.dispatch(onFieldsChange(formId, { "normalCredit": false }));

  return _this.props.dispatch(onFieldChange(formId, name, value));
}

export default onFieldChangeAccount;