import React from "react";

import ReactDOM from 'react-dom';

import DataGrid from 'react-data-grid';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import FileUpload from "components/CustomUpload/fileUpload";
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import { connect } from "react-redux";
import { simpleApi } from "redux/reducers/api";

import Switch from '@material-ui/core/Switch';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";


const styles = theme => {

  return {

    link: {
      color: theme.palette.primary[900],
      textTransform: "capitalize"
    },

  }
}

const mapStateToProps = (store, props) => {
  return {
  }
}


class BooleanFormatter extends React.Component {


  handleChange = (e) => {
    var checked = this.props.value == 1;
    if (checked) checked = false;
    else checked = true;


    this.props.handleUpdate({
      cellKey: this.props.column.key,
      updated: { [this.props.column.key]: checked },
      fromRowId: this.props.getRow(this.props.rowIdx).id
    })
  }

  render() {
    const { classes } = this.props;

    if (this.props.isScrolling) return null;

    return (
      <div>

        <Switch
          checked={this.props.value == 1}
          onChange={this.handleChange}

          color="primary"
        />



      </div>
    )
  }
}

const ClienteFormatterStyle = withStyles(styles)(connect(mapStateToProps)(BooleanFormatter));


export default function (column, reload, getRow, handleUpdate) {
  return class extends React.Component {
    render() {

      return <ClienteFormatterStyle handleUpdate={handleUpdate} reload={reload} getRow={getRow} column={column} {...this.props} />
    }
  }
}



