import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import Grid from "@material-ui/core/Grid";

import { Data, Toolbar, SingleSelectFilter } from "react-data-grid-addons";
import { connect } from "react-redux";
import ReactDataGrid from "react-data-grid";
import numeral from "numeral";

// material-ui components
import { selectDataByType, onLoadData, onSave, onAction, onReloadData } from "redux/reducers/data";

import TextFilter from "./TextFilter";
import NumericFilter from "./NumericFilter";
import DateTimeFilter from "./DateTimeFilter";
import DateFilter from "./DateFilter";
import BooleanFilter from "./BooleanFilter";

import SelectFilter from "./SelectFilter";
import { onFilter, selectFilterByKey, changeGroup, selectGroups } from "redux/reducers/table";
import List from "@material-ui/icons/List";
import { tooltip } from "assets/jss/material-dashboard-pro-react.jsx";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ChildFormatter from "./ChildFormatter";
import LinkFormatter from "./LinkFormatter";
import EditFormatter from "./EditFormatter";
import FileFormatter from "./FileFormatter";
import DateTimeFormatter from "./DateTimeFormatter";
import FileListFormatter from "./FileListFormatter";
import BooleanFormatter from "./BooleanFormatter";
import ConsecutivoFormatter from "./ConsecutivoFormatter";
import ClaveFormatter from "./ClaveFormatter";

import moment from "moment";

import lightGreen from "@material-ui/core/colors/indigo";
import Badge from "@material-ui/core/Badge";

import EmptyRowsView from "./Empty";

var EditorBase = ReactDataGrid.editors.EditorBase;

const filters = {
  TextFilter: TextFilter,
  NumericFilter: NumericFilter,
  DateFilter: DateFilter,
  DateTimeFilter: DateTimeFilter,
  SelectFilter: SelectFilter,
  BooleanFilter: BooleanFilter
};

const _formatters = {
  ChildFormatter: ChildFormatter,
  FileFormatter: FileFormatter,
  BooleanFormatter: BooleanFormatter,
  DateTimeFormatter: DateTimeFormatter,
  ConsecutivoFormatter: ConsecutivoFormatter,
  ClaveFormatter: ClaveFormatter,
  LinkFormatter: LinkFormatter,
  EditFormatter: EditFormatter,
  FileListFormatter: FileListFormatter
};

const editors = {};

const styles = {
  ...tooltip,

  selectFormControl: {
    marginTop: 6
  }
};

const RowRendererStyle = theme => ({
  root: {
    borderRadius: 0,

    backgroundColor: lightGreen[50]
  },
  cardRoot: {
    borderRadius: 0
  },
  title: {
    textTransform: "capitalize"
  },
  group: {
    height: 35,
    borderBottom: "1px solid #dedede"
  },
  groupName: {
    marginLeft: 15,
    marginRight: 15,
    marginTop: 3,
    textTransform: "capitalize",
    fontWeight: 600
  },
  iconButton: {
    marginLeft: 17,
    marginTop: 7
  },
  badge: {
    top: -11,
    right: -20,
    minWidth: 22,
    minHeight: 26,
    width: "auto",
    position: "relative",
    padding: 5,
    // The border color match the background color.
    border: `2px solid ${theme.palette.type === "light" ? theme.palette.grey[200] : theme.palette.grey[900]}`
  }
});

const RowRenderer = (dataRows, metadata) => {
  const RowRendererRaw = props => {
    const { classes } = props;

    let treeDepth = props.treeDepth || 0;
    let marginLeft = treeDepth * 20;

    let style = {};

    let onKeyDown = e => {
      if (e.key === "ArrowLeft") {
        props.onRowExpandToggle(false);
      }
      if (e.key === "ArrowRight") {
        props.onRowExpandToggle(true);
      }
      if (e.key === "Enter") {
        props.onRowExpandToggle(!props.isExpanded);
      }
    };

    var Icon = props.isExpanded ? ExpandLess : ExpandMore;

    var rows = dataRows;
    var rowValue = props.row.name;
    if (rowValue == "true") rowValue = true;
    if (rowValue == "false") rowValue = false;

    var rowKey = props.row.__metaData.columnGroupName;

    var sumColumnKey = metadata.table.sum;
    var sumColumValues = {};

    function processSum(item) {
      if (metadata.table.sum) {
        metadata.table.sum.forEach(sumColumnKey => {
          var value = sumColumValues[sumColumnKey] || {
            value: 0,
            column: metadata.properties[sumColumnKey]
          };
          value.value += item[sumColumnKey];
          sumColumValues[sumColumnKey] = value;
        });
      }
    }

    var thisRows = rows.filter(item => {
      if (item[rowKey] == rowValue) {
        processSum(item);
        return true;
      }
      return false;
    });

    var filaTitle = "Filas";
    if (thisRows.length == 1) filaTitle = "Fila";

    var sumLabel = "";
    if (metadata.table.sum) {
      Object.keys(sumColumValues).forEach(sumColumnValueKey => {
        const sumColumn = sumColumValues[sumColumnValueKey];
        sumLabel += ` ${sumColumn.column.title}: ${numeral(sumColumn.value).format("0,00.00")} | `;
      });
    }

    return (
      <div className={classes.group} onKeyDown={onKeyDown} tabIndex={0}>
        <Grid container>
          <Grid item>
            <Icon className={classes.iconButton} onClick={props.onRowExpandClick} />

            <Badge badgeContent={thisRows.length} color="primary" classes={{ badge: classes.badge }} />
          </Grid>
          <Grid item>
            <div className={classes.groupName}>
              <Grid container>
                <Grid item>
                  <div className={classes.groupName}>{props.name}</div>
                </Grid>

                <Grid item>
                  <div className={classes.groupName}>{sumLabel}</div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  };
  return withStyles(RowRendererStyle)(RowRendererRaw);
};

const mapStateToProps = (store, props) => {
  return {
    groups: selectGroups(store, props.metadataType)
  };
};

class SmartTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedIndexes: [],
      filters: {},
      columns: [],
      vista: {},
      expandedRows: [],
      rows: [],
      groupBy: null
    };
  }

  get formatters() {
    return { ..._formatters, ...(this.props.customFormatters || {}) };
  }

  componentDidMount() {
    this.setState({
      rows: this.props.dataRows ? this.props.dataRows.slice(0) : []
    });
    this.refs.grid.onToggleFilter();
    this.setGroup(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      rows: nextProps.dataRows ? nextProps.dataRows.slice(0) : []
    });
    this.setGroup(nextProps);
    if (this.state.selectedIndexes.length > 0 && nextProps.selectedIndexes.length == 0)
      this.clearSelectedIndexes();
  }

  reload = () => {
    this.props.dispatch(onReloadData(this.props.metadataType));
  };

  setGroup(props) {
    let groupBy = [];
    props.groups.forEach(groupKey => {
      var columnKey = groupKey;
      if (columnKey.indexOf("__") == 0) columnKey = columnKey.replace("__", "");
      var column = props.metadata.properties[columnKey];
      groupBy.push({ key: groupKey, name: column.title || column.key });
    });
    this.setState({ groupBy: groupBy });
  }

  getRows = () => {
    let rows = Data.Selectors.getRows(this.state);
    return rows;
  };

  getRowAt = index => {
    let rows = this.getRows();
    return rows[index];
  };

  getSize = () => {
    return this.getRows().length;
  };

  rowGetter = i => {
    return this.getRowAt(i);
  };

  onRowsSelected = rows => {
    var newRows = this.state.selectedIndexes.concat(rows.map(r => r.rowIdx));
    this.setState({ selectedIndexes: newRows });
    this.props.updateSelectedRows(newRows.map(rowIndex => this.rowGetter(rowIndex)));
  };

  onRowsDeselected = rows => {
    let rowIndexes = rows.map(r => r.rowIdx);
    var newRows = this.state.selectedIndexes.filter(i => rowIndexes.indexOf(i) === -1);
    this.setState({ selectedIndexes: newRows });
    this.props.updateSelectedRows(newRows.map(rowIndex => this.rowGetter(rowIndex)));
  };

  handleGridSort = (sortColumn, sortDirection) => {
    const compareNull = (a, b) => {
      if (a[sortColumn] == null || a[sortColumn].length == 0) return 1;
      if (b[sortColumn] == null || b[sortColumn].length == 0) return -1;
      if (a[sortColumn] == b[sortColumn]) return 0;
      return null;
    };

    const textComparer = (a, b) => {
      var _compareNull = compareNull(a, b);
      if (_compareNull != null) return _compareNull;

      if (sortDirection === "ASC") {
        return a[sortColumn] > b[sortColumn] ? 1 : -1;
      } else if (sortDirection === "DESC") {
        return a[sortColumn] < b[sortColumn] ? 1 : -1;
      }
    };

    const numberComparer = (a, b) => {
      var _compareNull = compareNull(a, b);
      if (_compareNull != null) return _compareNull;

      if (sortDirection === "ASC") {
        return parseFloat(a[sortColumn]) > parseFloat(b[sortColumn]) ? 1 : -1;
      } else if (sortDirection === "DESC") {
        return parseFloat(a[sortColumn]) < parseFloat(b[sortColumn]) ? 1 : -1;
      }
    };

    const dateComparer = (a, b) => {
      var _compareNull = compareNull(a, b);
      if (_compareNull != null) return _compareNull;

      if (sortDirection === "ASC") {
        return moment(a[sortColumn]).isAfter(moment(b[sortColumn])) ? 1 : -1;
      } else if (sortDirection === "DESC") {
        return moment(a[sortColumn]).isBefore(moment(b[sortColumn])) ? 1 : -1;
      }
    };

    var comparer = textComparer;
    var column = this.props.metadata.properties[sortColumn.replace("__", "")];

    if (column.element == "autocomplete") comparer = textComparer;
    else if (column.type == "number") comparer = numberComparer;
    else if (column.type == "integer") comparer = numberComparer;
    else if (column.filterRenderer == "DateFilter") comparer = dateComparer;

    const rows = sortDirection === "NONE" ? this.props.dataRows.slice(0) : this.props.dataRows.sort(comparer);
    this.setState({ rows });
  };

  groupBy = column => {
    this.props.dispatch(changeGroup(this.props.metadataType, column.key));
  };

  handleFilterChange = filter => {
    if (filter.value == "GROUP") return this.groupBy(filter.column);

    var { vista } = this.state;
    this.props.dispatch(onFilter(this.props.metadataType, filter));
  };

  dispatchFilter = filters => {
    this.props.dispatch(
      onLoadData(
        this.props.metadataType,
        Object.keys(filters).map(field => {
          return filters[field];
        })
      )
    );
  };

  getValidFilterValues = columnId => {
    let values = this.state.rows.map(r => r[columnId]);
    return values.filter((item, i, a) => {
      return i === a.indexOf(item);
    });
  };

  handleGridRowsUpdated = e => {
    var column = this.props.metadata.properties[e.cellKey];
    var value = e.updated[e.cellKey];
    if (column.type == "number") value = parseFloat(value);
    else if (column.type == "integer") value = parseInt(value);

    var payload = { id: e.fromRowId, [e.cellKey]: value };
    this.props.dispatch(onSave(this.props.metadataType, payload)).catch(e => {
      console.log(e);
    });
  };

  clearSelectedIndexes = () => {
    this.setState({ selectedIndexes: [] });
  };

  onApprove = e => {
    var ids = this.state.selectedIndexes.map(idx => {
      return this.rowGetter(idx).id;
    });
    this.props.dispatch(onAction(this.props.metadataType, "approve", ids));
  };

  onRowExpandToggle = ({ columnGroupName, name, shouldExpand }) => {
    let expandedRows = Object.assign({}, this.state.expandedRows);
    expandedRows[columnGroupName] = Object.assign({}, expandedRows[columnGroupName]);
    expandedRows[columnGroupName][name] = { isExpanded: shouldExpand };
    this.setState({ expandedRows: expandedRows, selectedIndexes: [] });
  };

  render() {
    var metadata = this.props.metadata;

    if (!metadata || !metadata.properties) return null;

    const columns = this.props.metadata.table.fields.map((key, index) => {
      var column = {
        ...metadata.properties[key],
        name: metadata.properties[key].title,
        key: key
      };
      column.metadataType = this.props.metadataType;

      if (column.element == "autocomplete") column.key = `__${column.key}`;
      else if (column.type == "number") column.formatter = NumberFormatter;

      if (index == 0) column.formatter = "EditFormatter";
      else if (key == "consecutivo") column.formatter = "ConsecutivoFormatter";
      else if (key == "clave") column.formatter = "ClaveFormatter";

      if (column.formatter) {
        var formatter = this.formatters[column.formatter] || null;
        if (formatter)
          column.formatter = formatter(
            column,
            this.reload,
            this.getRowAt,
            this.handleGridRowsUpdated,
            this.props
          );
      }

      if (column.filterable) {
        var defaultFilter = TextFilter;
        if (column.key == "createdAt") defaultFilter = DateTimeFilter;

        var filter = filters[column.filterRenderer] || TextFilter;
        column.filterRenderer = filter(this.props.metadataType);
      }

      if (column.editor) {
        //var Editor = editors[column.editor];
        column.editor = <SimpleTextEditor column={column} />;
      }

      if (!column.width) column.width = 100;

      return column;
    });

    const { classes } = this.props;
    var rowCount = this.getSize();

    return (
      <div>
        <div style={{ width: "calc(100%)" }}>
          <ReactDataGrid
            ref="grid"
            onShowModal={this.props.onShowModal}
            enableCellSelect={true}
            columns={columns || []}
            rowGetter={this.getRowAt}
            rowsCount={this.getSize()}
            minHeight={"calc(100vh - 178px)"}
            getCellActions={this.props.getCellActions}
            onAddFilter={this.handleFilterChange}
            onClearFilters={this.onClearFilters}
            onGridSort={this.handleGridSort}
            emptyRowsView={EmptyRowsView}
            onRowExpandToggle={this.onRowExpandToggle}
            toolbar={<EmptyToolbar groupBy={this.state.groupBy} enableFilter={true} />}
            getValidFilterValues={this.getValidFilterValues}
            rowGroupRenderer={RowRenderer(this.props.dataRows, this.props.metadata)}
            rowSelection={{
              showCheckbox: true,
              enableShiftSelect: true,
              onRowsSelected: this.onRowsSelected,
              onRowsDeselected: this.onRowsDeselected,
              selectBy: {
                indexes: this.state.selectedIndexes
              }
            }}
            onGridRowsUpdated={this.handleGridRowsUpdated}
            {...this.props.tableProps}
          />
        </div>
      </div>
    );
  }
}

class NumberFormatter extends React.Component {
  render() {
    return <div style={{ textAlign: "right" }}>{numeral(this.props.value).format("0,0.00")}</div>;
  }
}

class EmptyToolbar extends React.Component {
  componentDidMount() {
    this.props.onToggleFilter();
  }

  render() {
    return <div />;
  }
}

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps)(SmartTable));

class SimpleTextEditor extends EditorBase {
  onChange = e => {
    this.props.onBlur();
  };

  getValue() {
    let updated = {};
    var value = this.getInputNode().value;
    if (this.props.column.type == "boolean") value = value == 1;
    updated[this.props.column.key] = value;
    return updated;
  }

  render() {
    return (
      <input
        ref={node => (this.input = node)}
        type="date"
        onBlur={this.props.onBlur}
        className="form-control"
        defaultValue={this.props.value}
        onChange={this.onChange}
      />
    );
  }
}
