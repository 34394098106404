import React from 'react';
import PropTypes from 'prop-types';
import { shapes } from 'react-data-grid';
import TextField from '@material-ui/core/TextField';
import withStyles from "@material-ui/core/styles/withStyles";
import { onFilter, selectFilterByKey, selectGroupByKey, selectGroups, changeGroup } from "redux/reducers/table";
import GridOff from '@material-ui/icons/GridOff';
import { connect } from "react-redux";
import InputAdornment from '@material-ui/core/InputAdornment';
import ViewAgenda from '@material-ui/icons/ViewAgenda';

import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';

import Tooltip from '@material-ui/core/Tooltip';

import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import numeral from 'numeral';


const style = {
  chip: {
    marginLeft: "30px"
  }
}

const mapStateToProps = (store, props) => {
  return {
    groups: selectGroups(store, props.metadataType),
  }
}


class Sum extends React.Component {

  onClick = () => {
    // this.props.dispatch(  )
  }

  render() {
    const { classes } = this.props;
    if (!this.props.rows || !this.props.groups || this.props.groups.length == 0 || !this.props.metadata.table.sum) return null;

    var totales = {};
    this.props.rows.forEach((row) => {
      var sums = this.props.metadata.table.sum.forEach((field) => {
        if (!totales[field]) totales[field] = { name: field, total: 0 };
        totales[field].total += row[field];
      })
    })

    return Object.keys(totales).map((key) => {
      var total = totales[key]
      return <Tooltip title={total.name}>
        <Chip key={total.name}
          onClick={this.onClick}
          avatar={<Avatar>{total.name[0].toUpperCase()}</Avatar>}
          label={numeral(total.total).format('0,0.00')}
          className={classes.chip}
          variant="outlined"

        />
      </Tooltip>

    })

  }
}




export default withStyles(style, { withTheme: true })(connect(mapStateToProps)(Sum));