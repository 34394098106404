import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import red from '@material-ui/core/colors/red';
import FilterList from '@material-ui/icons/FilterList';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AddIcon from '@material-ui/icons/Add';

import Button from '@material-ui/core/Button';
import blue from '@material-ui/core/colors/blue';
import { onLoadMetaData, selectMetadataByType } from "redux/reducers/metadata";
import { selectDataByType, onLoadData, onReloadData, onSave, onAction } from "redux/reducers/data";
import { connect } from "react-redux";
import SmartTable from "components/SmartTable/Table";
import ActionMenu from "./actionMenu";


import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { changeLimit, selectLimit, selectSingleGroup } from "redux/reducers/table";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import Table from "components/Table/Table.jsx";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";



import numeral from "numeral";

export const styles = theme => ({
  ...extendedTablesStyle,
  root: {
    marginTop: "-8px",
    backgroundColor: "#fff"
  },
  main: {
    margin: theme.spacing.unit * 5,
    marginTop: 0
  },
  toolbar: {
    margin: theme.spacing.unit * 4,
    backgroundColor: "#fff",
    marginTop: "10px",
    marginBottom: 0
  },
  button: {
    margin: theme.spacing.unit * 1
  },
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  table: {
    marginTop: 100
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0
  },
  dialogRoot: {
    marginTop: 100
  },
  headerCell: {
    backgroundColor: "#333"
  },
  dialogTitle: {
    marginBottom: theme.spacing.unit * 1
  }

});

const mapStateToProps = (store, props) => {
  return {
    grouping: selectSingleGroup(store)
  }
}


class PrintView extends React.Component {
  constructor(props) {
    super(props);
    this.table = React.createRef();
  }

  state = {
    selectedItems: [],
    dialogPrint: false,
    printing: false,
    printView: false,
    printColumns: []
  }

  componentDidMount() {
    if (this.state.printColumns.length == 0 && this.props.grouping) this.setState({ printColumns: [this.props.grouping] })
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.printColumns.length == 0 && this.props.grouping) this.setState({ printColumns: [nextProps.grouping] })
  }

  onClosePrint = (e) => {
    this.setState({ printView: false });
    this.props.onClosePrint();
  }

  onPrint = (e) => {
    this.setState({ dialogPrint: true });
  }

  onPrintFromModal = (e) => {
    this.setState({ dialogPrint: false, printView: true });
  }

  onPrintFromView = (e) => {
    //window.print();

    printDiv(this.table.current);


  }

  handlePrintColumnToggle = (value) => {
    return () => {
      const { printColumns } = this.state;
      const currentIndex = printColumns.indexOf(value);
      const newChecked = [...printColumns];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      this.setState({
        printColumns: newChecked,
      });
    }
  };

  getGroupTableData() {
    var groups = {};
    var columnKeys = this.state.printColumns;
    var sumFields = this.props.metadata.table.sum;
    var totalFields = {};

    this.props.dataRows.forEach((row) => {
      var groupKey = row[this.props.grouping];
      var group = groups[groupKey];
      if (!group) group = { rows: [], name: groupKey, sums: {} };
      columnKeys.forEach((columnKey) => {
        if (sumFields.indexOf(columnKey) > -1) {
          if (!group.sums[columnKey]) group.sums[columnKey] = 0;
          group.sums[columnKey] += row[columnKey];
          if (!totalFields[columnKey]) totalFields[columnKey] = 0;
          totalFields[columnKey] += row[columnKey];
        }
      })
      group.rows.push(row);
      groups[groupKey] = group;
    })

    var rows = [];
    Object.keys(groups).forEach((groupKey) => {
      var groupRow = groups[groupKey];
      rows.push(columnKeys.map((key, index) => {
        if (index == 0) return groupRow.name;
        else return "";
      }))

      groupRow.rows.map((row) => {
        rows.push(columnKeys.map((key) => {
          var column = this.props.metadata.properties[key];
          if (column.element == "autocomplete") key = "__" + key;

          if (column.type == "number") return numeral(row[key]).format("0,0.00")

          return row[key];
        }))
      })

      rows.push(columnKeys.map((key, index) => {
        if (groupRow.sums[key]) return numeral(groupRow.sums[key]).format("0,0.00");
        else return "";
      }))

    })

    rows.push(columnKeys.map((key, index) => {
      if (totalFields[key]) return numeral(totalFields[key]).format("0,0.00");
      else return "";
    }))

    return rows;
  }

  getTableData() {
    if (this.props.grouping) return this.getGroupTableData();

    return this.props.dataRows.map((row) => {
      var keys = this.state.printColumns;
      return keys.map((key) => {
        var column = this.props.metadata.properties[key];
        if (column.element == "autocomplete") key = "__" + key;
        return row[key];
      })
    })
  }

  renderPrintView() {

    const { classes } = this.props;
    if (!this.state.printView) return null;

    return <Dialog
      classes={{
        root: classes.center,
        paper: classes.modal
      }}
      fullScreen={true}
      open={this.state.printView}
      onClose={this.onClosePrint}
      aria-labelledby="form-dialog-title"
    >

      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography className={classes.grow} variant="title" color="inherit" >
            Reporte {this.props.metadata.title}
          </Typography>
          <Button onClick={this.onClosePrint} color="inherit">
            Cancelar
            </Button>
          <Button onClick={this.onPrintFromView} color="inherit" autoFocus>
            Imprimir
            </Button>
        </Toolbar>
      </AppBar>

      <DialogContent classes={{ root: classes.dialogRoot }}>
        <div ref={this.table}>
          <Table
            className={classes.table}
            tableHead={this.state.printColumns.map((key) => {
              if (key.indexOf("__") == 0) key = key.replace("__", "");
              var column = this.props.metadata.properties[key];
              return column.title;
            })}
            tableData={this.getTableData()}
            customCellClasses={[
              classes.left,
              classes.right,
              classes.center
            ]}
            // 0 is for classes.center, 4 is for classes.right, 5 is for classes.right
            customClassesForCells={this.state.printColumns.map((key) => {
              var column = this.props.metadata.properties[key];
              if (column.element == "autocomplete") return 0;
              if (column.type == "string") return 0;
              else return 2;
            })
            }
            customHeadCellClasses={[
              classes.center,
              classes.right,
              classes.right
            ]}
            // 0 is for classes.center, 4 is for classes.right, 5 is for classes.right
            customHeadClassesForCells={
              this.state.printColumns.map((key) => {
                var column = this.props.metadata.properties[key];
                if (column.element == "autocomplete") return 0;
                if (column.type == "string") return 0;
                else return 1;
              })
            }
          />

        </div>
      </DialogContent>

    </Dialog>
  }



  renderSelectColumsForPrint() {
    const { classes } = this.props;
    if (!this.props.dialogOpen) return null;

    return <Dialog
      classes={{
        root: classes.center,
        paper: classes.modal
      }}
      maxWidth="md"
      open={this.props.dialogOpen}
      onClose={this.props.onClosePrint}
      aria-labelledby="form-dialog-title"
    >

      <DialogContent>
        <DialogContentText className={classes.dialogTitle}>
          Reportes para Imprimir
      </DialogContentText>
        <Grid container className={classes.root} spacing={16}>

          {this.props.metadata.table.fields.map((field) => {
            return <Grid item xs={4}>
              <ListItem

                key={field}
                role={undefined}
                dense
                button
                onClick={this.handlePrintColumnToggle(field)}
                className={classes.listItem}
              >
                <Checkbox
                  checked={this.state.printColumns.indexOf(field) !== -1}
                  tabIndex={-1}
                  disableRipple
                />
                <ListItemText primary={field} />

              </ListItem>
            </Grid>

          })
          }
        </Grid>

      </DialogContent>
      <DialogActions>
        <Button onClick={this.props.onClosePrint} color="primary">
          Cancel
            </Button>
        <Button onClick={this.onPrintFromModal} color="primary">
          Preparar Reporte
            </Button>
      </DialogActions>
    </Dialog>
  }




  render() {
    const { classes, theme } = this.props;
    if (!this.props.metadata) return null;


    return <div className={classes.root}>



      <div className={classes.main}>
        {this.renderSelectColumsForPrint()}
        {this.renderPrintView()}

      </div>
    </div>
  }
}


export default withStyles(styles, { withTheme: true })(connect(mapStateToProps)(PrintView));

function printDiv(printElement) {

  var printWindow = window.open('', 'PRINT');
  printWindow.document.write(document.documentElement.innerHTML);
  setTimeout(() => { // Needed for large documents
    printWindow.document.body.style.margin = '0 0';
    printWindow.document.body.innerHTML = printElement.outerHTML;
    printWindow.document.close(); // necessary for IE >= 10
    printWindow.focus(); // necessary for IE >= 10*/
    printWindow.print();
    printWindow.close();
  }, 1000)
}
