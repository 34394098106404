import Ajax from "../helpers/ajax";
import { onLoadDataSuccess, onLoadData, registerLastQuery } from "./data";
import { changeGroup } from "./table";
import { onWhoAmISuccess } from "./user";
import * as Sentry from '@sentry/browser';


import { selectFilters } from "./table";

const init = { byType: {} };

export function onLoadMetaData(metadataType, filters, queryParams) {
  return function (dispatch, getState) {
    var state = getState();
    var existingFilters = selectFilters(state, metadataType);
    if (existingFilters && queryParams.source == "table") {
      queryParams = null;
      filters = existingFilters;
    }

    if (state.metadata.byType[metadataType] && (filters || queryParams)) return dispatch(onLoadData(metadataType, filters, queryParams))

    if (!queryParams || !queryParams.id) {
      dispatch(registerLastQuery(metadataType, filters, queryParams))
    }

    return Ajax.post(dispatch, `crm/${metadataType}/metadata`, { filters: filters, ...queryParams })
      .then(function (results) {

        if (results.item) dispatch(onLoadDataSuccess(metadataType, [results.item]));
        else if (results.recent) dispatch(onLoadDataSuccess(metadataType, results.recent));
        delete results.item;
        delete results.recent;

        if (results.properties.estado) {
          dispatch(changeGroup(
            metadataType,
            "estado"
          ))
        }

        dispatch(onLoadMetadataSuccess(
          metadataType,
          results
        ))
        dispatch(onWhoAmISuccess(results))
        Sentry.configureScope((scope) => {
          scope.setUser({
            id: results.user.id,
            username: results.user.name,
            email: results.user.email
          });
        });

        return results;

      })
      .catch(function (apiError) {
        if (apiError.status == 404) alert("Error de integracion, el objeto no existe");
        console.log(apiError);
      })
  }
}

export function onLoadMetadataSuccess(metadataType, results) {

  var keys = Object.keys(results.properties);
  results.propertiesByKey = {};
  keys.forEach(function (key) {
    var column = results.properties[key];
    if (!column.ui) column.ui = {};
    if (!column.subType) column.subType = column.type;
    column.key = key;
    results.propertiesByKey[key] = column;
  })


  return {
    type: "META_DATA_LOAD_SUCCESS",
    payload: {
      results: results,
      metadataType: metadataType
    }
  }
}

export default (state = init, action) => {

  switch (action.type) {

    case "META_DATA_LOAD_SUCCESS":
      {
        var payload = action.payload;
        return {
          ...state,
          byType: { ...state.byType, [payload.metadataType]: payload.results }
        }
      }
  }
  return state;
}

export function selectMetadataByType(state, type) {

  return state.metadata.byType[type];
}