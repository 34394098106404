import { combineReducers } from "redux";
import user from "./user";
import table from "./table";
import form from "./form";
import header from "./header";
import metadata from "./metadata";
import data from "./data";
import api from "./api";
import feed from "./feed";
import socket from "./socket";
import electronico from "./electronico";

//import adminArea from './modules/adminArea'

export default combineReducers({
  user,
  table,
  form,
  header,
  metadata,
  data,
  api,
  feed,
  socket,
  electronico
});
