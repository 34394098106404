import React from "react";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "@material-ui/core/Button"
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardAction from "components/Card/CardAction.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import numeral from "numeral";

import CircularProgress from '@material-ui/core/CircularProgress';


import Close from "@material-ui/icons/Close";

// core components
import Table from "components/Table/Table.jsx";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import { Typography } from "@material-ui/core";


const styles = theme => ({
  ...extendedTablesStyle,
  containerRoot: {
    position: "relative",
    minHeight: "300px"
  },
  listContainerRoot: {
    position: "relative",
    maxHeight: "400px"
  },
  listRoot: {
    maxHeight: "100%",
    overflow: "scroll"
  },
  listItem: {
    paddingTop: "5px",
    paddingBottom: "5px",
    paddingLeft: "5px"

  },
  listItemText: {
    textTransform: "capitalize",
    fontSize: "12px"
  },
  progress: {
    margin: theme.spacing.unit * 2,
  },
})


class TableElement extends React.Component {




  getData() {


    var data = this.props.cliente.saldos.map((item, index) => {

      var keyArray = this.props.keys.map((key) => {
        return item[key]
      })
      return keyArray;

    }).sort((a, b) => a[1] < b[1] ? 1 : -1);

    data.forEach(element => {
      element[2] = element[2].toLocaleString();
    });

    return data;
  }

  getSum() {
    const sum = this.props.cliente.saldos.reduce((acc, saldo) => acc + saldo.total, 0);
    return sum;
  }

  renderLoading() {
    const { classes } = this.props;

    if (this.props.cliente.loading) return <CircularProgress
      className={classes.progress}
      color="secondary"
      thickness={7}
    />
    return null;
  }

  renderSum() {
    const { classes } = this.props;
    let sum = this.getSum();
    let warning = this.props.cliente.creditoLimite < sum ? '#ff4b00' : 'green'; 
    return <Typography variant="display1" style={{color:warning, fontSize:'18px'}}> {`${numeral(sum).format('0,00.00')}`} </Typography>
  }

  renderTable() {
    const { classes } = this.props;

    if (this.props.cliente.loading) return null;

    if (this.props.cliente.saldos.length == 0) return <Typography>No tiene saldos</Typography>

    return <Table
      tableHead={this.props.keys}
      tableData={this.getData()}
      customCellClasses={[
        classes.center,
        classes.right,
        classes.right
      ]}
      // 0 is for classes.center, 4 is for classes.right, 5 is for classes.right
      customClassesForCells={[0, 4, 5]}
      customHeadCellClasses={[
        classes.center,
        classes.right,
        classes.right
      ]}
      // 0 is for classes.center, 4 is for classes.right, 5 is for classes.right
      customHeadClassesForCells={[0, 4, 5]}
    />
  }

  render() {

    const { classes } = this.props;

    return <div >

      {this.renderLoading()}

      {this.renderSum()}

      {this.renderTable()}

    </div>


  }
}

export default withStyles(styles)(TableElement);

