import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PhoneIcon from "@material-ui/icons/Phone";
import FavoriteIcon from "@material-ui/icons/Favorite";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import HelpIcon from "@material-ui/icons/Help";
import ShoppingBasket from "@material-ui/icons/ShoppingBasket";
import ThumbDown from "@material-ui/icons/ThumbDown";
import ThumbUp from "@material-ui/icons/ThumbUp";
import Typography from "@material-ui/core/Typography";
import blue from "@material-ui/core/colors/blue";
import grey from "@material-ui/core/colors/grey";

import { Link } from "react-router-dom";
import Colibri from "assets/img/colibri.svg";
import Toolbar from "@material-ui/core/Toolbar";
import Snackbar from "components/snackbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import classNames from "classnames";
import LineLoader from "components/lineLoader";

import { connect } from "react-redux";

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: "100%"
  },

  logo: {
    width: "50px",

    right: 55,
    top: 10
  },
  root: {
    flexGrow: 1,
    textTransform: "capitalize"
  },

  flex: {
    flexGrow: 1
  },

  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  menuButton: {
    marginLeft: 0,
    marginRight: 16
  },
  hide: {
    display: "none"
  },

  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3
  },
  tabLabel: {
    color: grey[50]
  },
  tab: {
    width: "100%"
  },
  tabIndicator: {
    borderColor: theme.palette.primary[600]
  }
});

const mapStateToProps = (store, props) => {
  return {
    user: store.user.user
  };
};

class AppToolbar extends React.Component {
  state = {
    value: 0
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  getTabIndex = () => {
    var index = null;
    Object.keys(this.props.tabs).forEach((tabKey, counter) => {
      if (index == null && this.props.location.pathname.indexOf(tabKey) > -1) index = counter;
    });
    return index || 0;
  };

  render() {
    const { classes } = this.props;
    const { value } = this.state;

    return (
      <div className={classes.root}>
        <AppBar position="static" color="primary">
          <Snackbar />
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.props.handleDrawerOpen}
              className={classNames(classes.menuButton)}>
              <img className={classes.logo} src={Colibri} />
            </IconButton>

            <div>
              <Typography variant="headline" color="inherit" className={classes.logoName} noWrap>
                {this.props.user ? this.props.user.account : ""}
              </Typography>
            </div>

            <Tabs
              className={classes.tab}
              fullWidth={true}
              centered={false}
              value={this.props.location.pathname.split("/").pop()}
              onChange={this.handleChange}
              scrollable={false}
              scrollButtons="auto">
              {Object.keys(this.props.tabs).map(tabKey => (
                <Tab
                  classes={{ selected: classes.tabIndicator, label: classes.tabLabel }}
                  key={tabKey}
                  value={tabKey.split("/").join("")}
                  component={Link}
                  to={`/${this.props.namespace}/${tabKey}`}
                  label={this.props.tabs[tabKey].title}
                />
              ))}
            </Tabs>
          </Toolbar>
          <LineLoader />
        </AppBar>
      </div>
    );
  }
}

AppToolbar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps)(AppToolbar));
