import Ajax from "../helpers/ajax";
import { subscribeToExternalChannel } from "redux/reducers/socket";

const init = { documentoById: {} };

export function onLoadDocumentos() {
  return function(dispatch, getState) {
    var state = getState();

    dispatch(subscribeToExternalChannel("DocumentoElectronico", addDocumento));

    return Ajax.post(dispatch, `crm/notifications/documentos`, {}).then(
      results => {
        dispatch(onLoadDocumentosSuccess(results));
      }
    );
  };
}

export function addDocumento(documentoMessage) {
  var documento = documentoMessage;
  return {
    type: "ADD_DOCUMENTO_ELECTRONICO",
    payload: {
      documento: documento
    }
  };
}

export function onLoadDocumentosSuccess(results) {
  var map = {};
  results.forEach(item => {
    map[item.clave] = item;
  });

  return {
    type: "DOCUMENTO_ELECTRONICO_SUCCESS",
    payload: {
      map: map
    }
  };
}

export default (state = init, action) => {
  switch (action.type) {
    case "DOCUMENTO_ELECTRONICO_SUCCESS": {
      var payload = action.payload;

      let result = {
        ...state,
        documentoById: payload.map
      };
      return result;
    }

    case "ADD_DOCUMENTO_ELECTRONICO": {
      console.log("ok");
      var incomingDocumento = action.payload.documento;
      var originalDocumento =
        state.documentoById[incomingDocumento.clave] || {};

      let result = {
        ...state,
        documentoById: {
          ...state.documentoById,
          [incomingDocumento.clave]: {
            ...originalDocumento,
            ...incomingDocumento
          }
        }
      };
      return result;
    }
  }
  return state;
};

export function selectDocuments(state) {
  var keys = Object.keys(state.electronico.documentoById);
  var items = keys.map(key => {
    return state.electronico.documentoById[key];
  });
  items.sort(function(a, b) {
    if (a.createdAt > b.createdAt) {
      return -1;
    } else {
      return 1;
    }
  });
  return items;
}

function mapOrder(array, order, key) {
  array.sort(function(a, b) {
    var A = a[key],
      B = b[key];

    if (order.indexOf(A) > order.indexOf(B)) {
      return 1;
    } else {
      return -1;
    }
  });

  return array;
}
