import { alert } from "redux/reducers/header";
import { whoAmI } from "redux/reducers/user";

var Ajax = {
  authorization_code: null,
  cookieLoaded: false
}

Ajax.getURL = function (path, params) {
  if (path[0] !== "/") path = "/" + path
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "dev" || process.env.NODE_ENV === "development") return "http://localhost:4000" + path
  else return "/api" + path


  if (path.indexOf("http") === 0) return path;
  if (path[0] !== "/") path = "/" + path
  if (!params) params = {};
  var url = window.api_url || process.env.API_URL;
  return path;
}

Ajax.logout = function () {
  window.localStorage.setItem("authorization", null);
  window.location.reload();
}

Ajax.getHeaders = function (withToken) {
  var authorization = window.localStorage.getItem("authorization")
  var account = window.localStorage.getItem("account");

  if (authorization && authorization.length > 1 && withToken !== false) return new Headers({
    'Content-Type': 'application/json',
    'x-authorization': authorization,
    'x-account': account || ""
  })
  else return new Headers({
    'Content-Type': 'application/json',
    'x-account': account || ""
  })
}

Ajax.handleResponse = function (status, type, url, response, headers, dispatch) {
  var id = parseInt(Math.random() * 1000000);

  if (status < 300) return Promise.resolve(response);
  else if (status > 403 && status <= 500) {
    dispatch(alert(response.label, "danger", true))
    return Promise.reject(response);
  }

  else if (status === 401) dispatch({ "type": "AJAX_401", payload: { id: id, status: status, error: response } })
  else if (status === 402) dispatch({ "type": "AJAX_402", payload: { id: id, status: status, error: response } })
  else if (status === 403) dispatch(alert(response.label, "danger", false))

  else {
    dispatch(alert(response.label, "danger", false))
    dispatch({ "type": "AJAX_500", payload: { id: id, status: status, error: response } })
  }
  return Promise.reject(response);
}


Ajax.get = function (dispatch, path, params) {
  //dispatch(whoAmI());
  Ajax.getAuthCookie();
  var status;
  var headers;
  var url = Ajax.getURL(path, params);

  dispatch({ type: "AJAX_START" })

  return fetch(url, {
    headers: Ajax.getHeaders(true),
    mode: "cors",
    method: "GET",

  })
    .then(function (response) {
      dispatch({ type: "AJAX_COMPLETE" })

      headers = response.headers;
      status = response.status;
      if (status === 401) return {};
      if (response[0] !== "{") return JSON.stringify(response);
      return response.json();
    })
    .then(function (response) {
      return Ajax.handleResponse(status, "GET", url, response, headers);
    })
    .catch(function (err) {
      dispatch({ type: "AJAX_COMPLETE" });
      dispatch({ "type": "AJAX_UNAVAILABLE", payload: { error: err } });
      throw err;
    })
}

Ajax.post = function (dispatch, path, body, params) {
  // if (path.indexOf("whoAmI") === -1) dispatch(whoAmI());

  Ajax.getAuthCookie();
  var status;
  var headers;
  var url = Ajax.getURL(path, params);

  dispatch({ type: "AJAX_START" })

  return fetch(url, {
    headers: Ajax.getHeaders(true),
    body: JSON.stringify(body),
    mode: "cors",
    method: "POST",
  })
    .then(function (response) {
      dispatch({ type: "AJAX_COMPLETE" })
      headers = response.headers;
      status = response.status;
      return response.json();
    })
    .then(function (response) {
      return Ajax.handleResponse(status, "POST", url, response, headers, dispatch);
    })
    .catch(function (err) {
      dispatch({ type: "AJAX_COMPLETE" });
      dispatch({ "type": "AJAX_UNAVAILABLE", payload: { error: err } });
      throw err;
    })
}

Ajax.put = function (path, body, params) {
  Ajax.getAuthCookie();
  var status;
  var headers;
  var url = Ajax.getURL(path, params);
  return fetch(url, {
    headers: Ajax.getHeaders(true),
    body: JSON.stringify(body),
    mode: "cors",
    method: "PUT",

  })
    .then(function (response) {
      headers = response.headers;
      status = response.status;
      return response.json();
    })
    .then(function (response) {
      return Ajax.handleResponse(status, "PUT", url, response, headers);
    })
    .catch(function (err) {
      if (!headers) headers = {};
      return {
        errorType: "AJAX_HTTP_ERROR",
        error: err
      };
    })
}

Ajax.delete = function (path, params) {
  Ajax.getAuthCookie();
  var status;
  var headers;
  var url = Ajax.getURL(path, params);
  return fetch(url, {
    headers: Ajax.getHeaders(true),
    mode: "cors",
    method: "DELETE",

  })
    .then(function (response) {
      headers = response.headers;
      status = response.status;
      return response.json();
    })
    .then(function (response) {
      return Ajax.handleResponse(status, "DELETE", url, response, headers);
    })
    .catch(function (err) {
      if (!headers) headers = {};
      return {
        errorType: "AJAX_HTTP_ERROR",
        error: err
      };
    })
}

Ajax.login = function (dispatch, path, body, params) {
  var status;
  var headers;
  var url = Ajax.getURL(path, params);

  dispatch({ type: "AJAX_START" })

  return fetch(url, {
    headers: Ajax.getHeaders(false),
    body: JSON.stringify(body),
    mode: "cors",
    method: "POST",
  })
    .then(function (response) {
      dispatch({ type: "AJAX_COMPLETE" })
      headers = response.headers;
      status = response.status;
      return response.json();
    })
    .then(function (response) {
      return Ajax.handleResponse(status, "POST", url, response, headers, dispatch);
    })
    .catch(function (err) {
      dispatch({ type: "AJAX_COMPLETE" });
      dispatch({ "type": "AJAX_UNAVAILABLE", payload: { error: err } });
      throw err;
    })
}

Ajax.getAuthCookie = function () {
  if (Ajax.cookieLoaded) return;
  Ajax.cookieLoaded = true;
  var cookies = document.cookie.split('; ');
  cookies.forEach(function (cookie) {
    var parts = cookie.split("=");
    if (parts[0] === "token__" + process.env.NODE_ENV && parts[1].length > 10) Ajax.authorization_code = parts[1];
    else if (parts[0] === "user_name__" + process.env.NODE_ENV) Ajax.userName = parts[1];
    else if (parts[0] === "namespace_id__" + process.env.NODE_ENV) Ajax.namespace_id = parts[1];
  })
}

Ajax.setCookie = function (name, value) {
  var domain = window.domain || process.env.DOMAIN;

  name = name + "__" + process.env.NODE_ENV;
  var cookie = name + "=" + value + "; Path=/;";
  if (window.location.href.indexOf("localhost") === -1) cookie += " Domain=" + domain + "; ";
  document.cookie = cookie
}

export default Ajax;
