import { alert } from "redux/reducers/header";
import { onFieldChange, onFieldsChange } from "redux/reducers/form";

import moment from "moment"

const onFacturaCxPFieldChange = (_this, formId, name, value, extra) => {


  var form = _this.props.form.item;
  if (name == "total") return null;

  if (name == "proveedorId") {
    var newItem = _this.props.dispatch(onFieldChange(formId, name, value))
      .then((newItem) => {
        _this.props.dispatch(onFieldChange(formId, "fechaVencimiento", moment().add(extra.plazoPago || 0, "days").format("YYYY-MM-DD")))
        _this.props.dispatch(onFieldChange(formId, "fechaProgramacion", moment().add(extra.plazoPago || 0, "days").format("YYYY-MM-DD")))
      })
  }

  if (["subTotal", "descuento", "impuesto"].indexOf(name) > -1) {
    var newItem = _this.props.dispatch(onFieldChange(formId, name, value))
      .then((newItem) => {
        if (!newItem.subTotal) newItem.subTotal = 0;
        if (!newItem.impuesto) newItem.impuesto = 0;
        if (!newItem.descuento) newItem.descuento = 0;
        return _this.props.dispatch(onFieldChange(formId, "total", newItem.subTotal + newItem.impuesto - newItem.descuento))
      })

  }
  else _this.props.dispatch(onFieldChange(formId, name, value));

}

export default onFacturaCxPFieldChange;

