import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

class FileListFormatter extends React.Component {
  render() {
    var value = this.props.value;

    if (!value || value.length == 0) return null;

    return value.map(item => {
      var url = item;
      if (this.props.column.prefix) item.key = this.props.column.prefix + item.key;

      return (
        <a style={{ margin: 6 }} target="_blank" href={item.key}>
          {item.type}
        </a>
      );
    });
  }
}

export default function(column, reload, getRow, handleUpdate) {
  return class extends React.Component {
    render() {
      return (
        <FileListFormatter
          handleUpdate={handleUpdate}
          reload={reload}
          getRow={getRow}
          column={column}
          {...this.props}
        />
      );
    }
  };
}
