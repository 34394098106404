import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
//import registerServiceWorker from './registerServiceWorker';
import { AppContainer } from "react-hot-loader";
import { BrowserRouter } from "react-router-dom";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import deepPurple from "@material-ui/core/colors/deepPurple";
import blueGrey from "@material-ui/core/colors/blueGrey";

import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import configureStore from "./redux/store";
import { ConnectedRouter } from "connected-react-router";
import ErrorBoundery from "./ErrorBoundery";
import * as Sentry from "@sentry/browser";

const hist = createBrowserHistory();
const reduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();

const store = configureStore();

const theme = createMuiTheme({
  palette: {
    primary: deepPurple,
    secondary: blueGrey
  },
  typography: {
    fontWeightRegular: 300,
    fontWeightMedium: 400
  }
});

//registerServiceWorker();

const render = Component => {
  if (process.env.NODE_ENV != "development")
    Sentry.init({
      dsn: "https://3e63208a7b0f4976b7388560eb6d2006@sentry.io/1288930",
      environment: process.env.NODE_ENV
    });

  ReactDOM.render(
    <AppContainer>
      <Provider store={store}>
        <ConnectedRouter history={hist}>
          <MuiThemeProvider theme={theme}>
            <ErrorBoundery>
              <App />
            </ErrorBoundery>
          </MuiThemeProvider>
        </ConnectedRouter>
      </Provider>
    </AppContainer>,
    document.getElementById("root")
  );
};

render(App);

if (module.hot) {
  module.hot.accept("./App", () => {
    const NextApp = require("./App").default;
    render(NextApp);
  });
}

String.prototype.fromClave = function() {
  return this.substring(34, 41);
};
